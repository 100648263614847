import { getAPIurl } from "../../Components/APICall";
import MagButton from "../../Components/MagButton";
import { getAltBackgroundColor, getTitleBackgroundColor } from "../Themes/ThemeColors"

function GeneralTitle({width, theme, title, slogan, ideas, plansRef, setVideo, presentationVideo}) {
    const tokens = ideas ? ideas.split('|') : [];
    const aideas = [];
    for (let i = 0; i < tokens.length; i += 2) {
        aideas.push({icon: tokens[i], text: tokens[i + 1]});
    }
    const videoUrl = getAPIurl('media/hls', {name_i: presentationVideo}) + '&kind=video/mp4';
                  
    return <div style={{width: width >= 728 ? "50%" : "100%", backgroundColor: getTitleBackgroundColor(theme),
        color: "white", display: "flex", flexDirection: "column", alignItems: "center"}} className="pad24">
        <p className="center top24 bot16 xxxlarge">{title}</p>
        <img alt="" src="/bkg/decor/sep1.png" width="140px"/>
        <p className="top18 large">{slogan}</p>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}} className="top36">
            {aideas.map((idea, i) => {
                return <div key={i} className="center" style={{margin: "4px", width: "128px"}}>
                    <img alt="" src={"/bkg/ideas/" + idea.icon + ".png"} height="48px"/>
                    <p className="top8 small">{idea.text}</p>
                </div>
            })}
        </div>
        <div style={{display: "flex", justifyContent: "space-between", width: "336px", maxWidth: "100%"}}
            className="top36">
            <MagButton text="дізнатися деталі" clickEff={{scrollRef: plansRef}}/>
            {presentationVideo > 0 &&
            <MagButton text="про курс" icon="/bkg/play.png" bkgColor={getAltBackgroundColor(theme)}
                color="black" clickEff={{customFnc: () => { setVideo({id: videoUrl, title: title}); }
            }}/>}
        </div>
    </div>
}

export default GeneralTitle