import { useEffect, useState } from "react";
import DocPreview from "../../Components/DocPreview";
import InfiniteScroll from "react-infinite-scroll-component";
import Translate from "../../AppContext/Translate";
import SyncLoader from "react-spinners/SyncLoader";
import Project from "./Project";
import { getAPICall } from "../../Components/APICall";

function ShowProjects({courseId}) {
    const [state, setState] = useState({items: [], hasMore: true, offset: 0});
    const [preview, setPreview] = useState(null);
    const limit = 48;

    const projectsSuccess = (data, reset) => {
        if (data && data.length > 0) {
            if (reset)
                setState({
                    items: data,
                    hasMore: data.length >= limit,
                    offset: data.length
                });
            else
                setState({
                    items: state.items.concat(data),
                    hasMore: data.length >= limit,
                    offset: state.offset + data.length
                });
        } else projectsError(data);
    }

    const projectsError = (data) => {
        setState({items: state.items, hasMore: false, offset: state.offset});
    }

    const fetchMoreData = (reset=false) => {
        getAPICall('tasks/projects', {courseId: courseId, offset: reset ? 0 : state.offset, limit: limit},
            (data) => { projectsSuccess(data, reset); }, projectsError);
    }

    useEffect(() => {
        fetchMoreData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    const onPreview = (data) => {
        setPreview(data);
    }

    return <div style={{padding: "10px", backgroundColor: "white"}}>
        {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} 
            path={preview["path"]}/>}
        <div id="projScrollableDiv" className="bot16">
            {state.items.length === 0 ?
            <>
            <Translate>У цьому курсі ще немає опублікованих проектів</Translate>. 
            </>
            :
            <InfiniteScroll
                dataLength={state.items.length}
                next={fetchMoreData}
                hasMore={state.hasMore}
                loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}
                scrollableTarget="projScrollableDiv"
                endMessage={<></>}>
                {state.items.map((data, index) => 
                    <Project key={index} data={data} onPreview={onPreview}/>)}
            </InfiniteScroll> }
        </div>
    </div>
}

export default ShowProjects