import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import { Navigate } from "react-router-dom";
import Menu from "../../Menu/Menu";
import CabTitle from "../CabTitle";
import BeatLoader from "react-spinners/BeatLoader";
import Footer from "../../Components/Footer";
import AdminSchools from "./AdminSchools";
import CabHomeButton from "../CabHomeButton";
import AdminCourses from "./AdminCourses";
import AdminPayments from "./AdminPayments";
import MagBanner from "../../Components/MagBanner";

function CabinetAdmin() {
    const { userId, token } = useContext(AppContext);
    const [ admin, setAdmin ] = useState(null);

    useEffect(() => {
        if (userId >= 0) {
            getAPICall('cabinet/admin', {userId: userId, token: encodeURIComponent(token)},
                (data) => {
                    if (data && 'admin' in data) {
                        setAdmin(data.admin === 1);
                    } else setAdmin(false);
                }, () => { setAdmin(false); });
        }
    }, [userId, token]);

    return (userId < 0 || admin === false) ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Адміністрація" icon="/icons/admin.png" bkgColor="#fef5de"/>
        <div style={{padding: "10px"}}>
            <CabTitle/>
            {admin === true ? <>
                <AdminSchools/>
                <AdminCourses/>
                <AdminPayments/>
            </> : <BeatLoader color="blueviolet"/>}
            <CabHomeButton/>
        </div>
        <Footer/>
    </div>
}

export default CabinetAdmin