import { lazy, Suspense } from 'react';
import { getAltBackgroundImageColor } from '../Themes/ThemeColors';
import Translate from '../../AppContext/Translate';

const BackgroundImages = lazy(() => import('../Themes/BackgroundImages'));

function GeneralAudienceCover({width, backImage, frame, theme, category}) {
    return <div style={{width: width >= 720 ? "50%" : "100%", maxWidth: "520px", margin: "auto"}}>
        <Suspense>
            <BackgroundImages id={backImage + 1} sty={{color: getAltBackgroundImageColor(theme),
                width: "100%", height: "auto", maxWidth: "500px"}}/>
        </Suspense>
        <img alt="" src={"/bkg/themes/c" + category + "_t" + (theme + 1) + "_6.png"}
            style={{width: "40%", position: "absolute", top: "30%", left: "5%", zIndex: "50"}}/>
        <div style={{width: "60%", position: "absolute", top: "55%", left: "25%", zIndex: 100,
            backgroundColor: "#ffffffbf", borderRadius: "16px"}} className='pad16 xxlarge'>
            <p className='bold'><Translate>ЦЕЙ КУРС ДЛЯ ВАС</Translate>,</p>
            <p><Translate>ЯКЩО ВИ</Translate>:</p>
        </div>
        {width < 720 && <img alt="" src="/bkg/decor/m.png" style={{position: "absolute", bottom: "0px", right: "0px",
                width: "30%", maxWidth: "214px", minWidth: "90px"}}/>}
    </div>
}

export default GeneralAudienceCover