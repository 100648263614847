import { useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import MagBanner from "../Components/MagBanner";
import Translate from "../AppContext/Translate";
import InfiniteScroll from "react-infinite-scroll-component";
import SyncLoader from "react-spinners/SyncLoader";
import UserPhotoIcon from "../Components/UserPhotoIcon";
import HomeButton from "../Components/HomeButton";

function Teacher({data}) {
    return <div style={{margin: "24px 16px"}}>
        <div style={{marginLeft: "8px"}}>
            <UserPhotoIcon user={data.teacher} userName={data.name} big={true}/>
        </div>
        <div className="categoryCardTitle" style={{height: "32px"}}>
            {data.name}
        </div>
        <div className="categoryCardFooter">
            {data.numCourses} <Translate>курсів</Translate>
        </div>
    </div>
}

function Teachers() {
    const [state, setState] = useState({items: [], hasMore: true, offset: 0});
    const limit = 48;

    const onSuccess = (data) => {
        if (data && data.length > 0) {
            setState({
                items: state.items.concat(data),
                hasMore: data.length >= limit,
                offset: state.offset + data.length
            });
        } else onError(data);
    }

    const onError = (data) => {
        setState({items: state.items, hasMore: false, offset: state.offset});
    }

    const fetchMoreData = () => {
        getAPICall('teachers/list', {offset: state.offset, limit: limit},
            onSuccess, onError);
    }

    useEffect(() => {
        fetchMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Викладачі" bkgColor="#fee6dd" icon="/icons/students.png"/>
        <div className="magSection" style={{flexDirection: "column"}}>
            <p className="large bold top18"><Translate>Викладачі на нашій платформі</Translate>:</p>
            <InfiniteScroll
                dataLength={state.items.length}
                next={fetchMoreData}
                hasMore={state.hasMore}
                loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                style={{display: "flex", flexWrap: "wrap"}}
                endMessage={null}>
                {state.items.map((data, index) => 
                    <Teacher key={index} data={data}/>)}
            </InfiniteScroll>
        </div>
        <HomeButton/>
        <div style={{height: "24px"}}/>
        <Footer/>
    </div>
}

export default Teachers