import { useEffect, useContext, useState } from "react";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import { Link } from "react-router-dom";
import PostMessage from "../Components/PostMessage";

function UserProduct({item}) {
    const { userId, token } = useContext(AppContext);
    const [data, setData] = useState(null);
    const pic = "url('/items/item" + item + ".jpg')";
    const cover = <div style={{width: "100%", height: "100%", backgroundSize: "cover", backgroundImage: pic,
        backgroundPosition: "50% 50%"}}/>

    useEffect(() => {
        if (userId >= 0) {
            getAPICall('shop/item', {item: item},
            (info) => {
                console.log(item, info);
                if (info && info.length > 0) setData(info[0]);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token, item]);

    return <div className="userPlanCard">
        <div style={{display: "flex"}}>
            <div style={{width: "40%"}}>
                <Link to={"/product/" + item}>{cover}</Link>
            </div>
            <div style={{padding: "8px", borderLeft: "thick double darkgray", width: "60%", minHeight: "140px"}}>
                {data && <>
                    <Link to={"/product/" + item} style={{color: "black"}}>
                        <PostMessage msg={data.name} open={false} sep={false} textStyle="textPost"/>
                    </Link>
                    <p style={{color: 'darkcyan'}}>
                        <Link className="schoolLink" to={"/school/" + data.school}>{data.schoolName}</Link>
                    </p>
                </>}
            </div>
        </div>
    </div>
}

export default UserProduct