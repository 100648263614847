import Form from 'react-bootstrap/Form';
import { translate } from '../../../AppContext/Translate';

function ColorTheme({theme, setTheme, setChanges, dicLang}) {
    const themes = ["М'ятний", "Золотий", "Рожевий"];

    return <div style={{display: "flex", justifyContent: "space-between"}}>
        {themes.map((color, i) => {
            return <div key={i} style={{width: "30%"}}>
                <Form.Check type="radio" id={`theme-${i+1}`} name="themes" label={translate(color, dicLang)}
                    checked={theme === i} onChange={() => { setTheme(i); setChanges(true); }}/>
                <img alt="" src={"/bkg/theme" + (i+1) + ".jpg"} style={{width: "100%"}}/>
            </div>
        })}
    </div>
}

export default ColorTheme