import { Link } from "react-router-dom"
import Translate from "../AppContext/Translate"
import HomeButton from "../Components/HomeButton"

function ThankYou() {
    return <div className="magSection" style={{backgroundColor: "#d9f5ed", maxWidth: "800px", margin: "48px auto",
        flexDirection: "column", alignItems: "center"}}>
        <img alt="" src="/logo192.png" width="84px" style={{marginTop: "-56px"}}/>
        <p className="top18 bold large"><img alt="" src="/icons/check.png" width="48px" className="right18"/><Translate>Дякуємо! Ваше замовлення прийнято</Translate>.</p>
        <p><Translate>Незабаром ви отримаєте електронний лист з деталями вашого замовлення</Translate>.</p>
        <div style={{height: "1.5px", width: "75%", backgroundColor: "black"}} className="top18"/>
        <p className="top18 large italic"><Translate>Ми неймовірно раді, що ви стали частиною нашої спільноти!</Translate></p>
        <p className="top8"><Translate>Якщо ви придбали курс, ви можете отримати доступ до нього через розділ меню</Translate> «<Link to="/user/courses">Мої курси</Link>».</p>
        <p><Translate>Якщо ви придбали електронний товар, ви вже можете переглянути його через розділ меню</Translate> «<Link to="/user/products">Мої товари</Link>».</p>
        <p><Translate>Якщо ви придбали фізичний товар, ви можете переглянути статус доставки у розділі</Translate> «<Link to="/user/orders">Мої замовлення</Link>».</p>
        <div style={{height: "1.5px", width: "75%", backgroundColor: "black"}} className="top18"/>
        <p className="top18 bot24"><Translate>Якщо у вас є будь-які запитання чи пропозиції, пишіть нам на</Translate> <a href="mailto:support@magistrika.com">support@magistrika.com</a></p>
        <HomeButton/>
    </div>
}

export default ThankYou