import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import SchoolCover from "./SchoolCover";
import { getAPICall } from "../Components/APICall";
import { useNavigate } from "react-router-dom";
import Translate from "../AppContext/Translate";
import InfiniteScroll from 'react-infinite-scroll-component';
import SyncLoader from "react-spinners/SyncLoader";
import Item from "../Shop/Item";
import Footer from "../Components/Footer";
import PostMessage from "../Components/PostMessage";
import { MediaPath } from "../Components/MediaPath";
import SchoolCourses from "./SchoolCourses.js";
import HomeButton from "../Components/HomeButton.js";
import SchoolLogo from "./SchoolLogo.js";
import { useWindowSize } from "../AppContext/WindowSize.js";
import CabinetButton from "../User/Profile/CabinetButton.js";
import { AppContext } from "../AppContext/AppContext.js";
import SchoolMember from "./SchoolMember.js";
import HorizontalScroll from "../Components/HorizontalScroll.js";

function School() {
    const id = useParams()["id"];
    const { userId } = useContext(AppContext);
    const [schoolId, setSchoolId] = useState(() => {
        if (id !== null) {
            var sid = parseInt(id);
            return isNaN(sid) ? null : sid;
        }
        return id;
    });
    const [schoolData, setSchoolData] = useState(null);
    const [teachers, setTeachers] = useState(null);
    const [courses, setCourses] = useState(null);
    const [products, setProducts] = useState({items: [], hasMore: true, offset: 0});
    const [directorName, setDirectorName] = useState(null);
    const [width,] = useWindowSize();
    const limit = 32;
    let navigate = useNavigate();

    const itemsSuccess = (data) => {
        if (data && data.length > 0) {
            setProducts({
                items: products.items.concat(data),
                hasMore: data.length >= limit,
                offset: products.offset + data.length
            });
        } else itemsError(data);
    }
    const itemsError = (data) => {
        setProducts({items: products.items, hasMore: false, offset: products.offset});
    }

    const fetchMoreData = () => {
        getAPICall('shop/items', {lang: '', school: schoolId, kind: 0, offset: products.offset, limit: limit},
            itemsSuccess, itemsError);
    }

    useEffect(() => {
        const getTeachersOk = (data) => {
            if (data && data.length > 0)
                setTeachers(data);
            }
        const getCoursesOk = (data) => {
            if (data && data.length > 0)
                setCourses(data);
        }
        if (schoolId !== null) {
            getAPICall('school/data', {school: schoolId}, (data) => {
                if (data && data.length > 0) {
                    setSchoolData(data[0]);
                    getAPICall('user/name', {user: data[0].adminUser}, (data) => {
                        if (data && data.length > 0) setDirectorName(data[0].name);
                    });
                    getAPICall('school/teachers', {school: schoolId}, getTeachersOk);
                    getAPICall('school/courses', {school: schoolId, active: 1}, getCoursesOk);
                    fetchMoreData();
                } else setSchoolId(null);
            }, () => { setSchoolId(null); });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolId]);
    
    const routeChange = (path) => { 
        navigate(path);
    }
    
    var path = new MediaPath(MediaPath.kind_publicSchool);
    path.add(schoolId);
    path.addStr('desc');

    return !schoolId || schoolId < 0 ? <Navigate to="/" replace={true}/> : 
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <div style={{backgroundColor: "#d9f5ed"}}>
            <Menu />
            <div style={{width: "100%", maxWidth: "1024px", margin: "auto"}}>
                <SchoolCover school={schoolId} allowChange={false}/>
                <div className="pad12" style={{display: "flex", alignItems: "center", 
                    justifyContent: width < 650 ? "flex-end" : "space-between",
                    flexWrap: width < 650 ? "wrap" : "nowrap"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <SchoolLogo schoolId={schoolId} size={width < 600 ? 'small' : 'med'}/>
                        {schoolData && <p className="fmak xlarge bold" style={{marginLeft: "12px", zIndex: 100}}>{schoolData.title}</p>}
                        <img alt="" src="/bkg/decor/dec13.png" style={{position: "absolute", right: "32px",
                            top: "75%", width: "32px"}}/>
                    </div>
                    {schoolData && userId === schoolData.adminUser &&
                    <div style={{display: "flex", flexDirection: "column", minWidth: "159px"}}>
                        <button className="bot8" style={{backgroundColor: "#12c191", color: "white", border: "none",
                            padding: "3px 10px"}} onClick={() => { routeChange("/cabinet/school"); }}>
                            <img alt="edit" src="/icons/edit.png" width="22px" className="right6"/><Translate>Редагувати</Translate>
                        </button>
                        <CabinetButton/>
                    </div>}
                </div>
                <div className="magSection" style={{backgroundColor: "white", display: "block", minHeight: "190px"}}>
                    {directorName && schoolData && <div className="magSection" style={{backgroundColor: "#d9f5ed",
                        float: width >= 650 ? "right" : "none", 
                        width: width >= 650 ? "280px": "auto", flexDirection: "column", zIndex: 100}}>
                        <p className="fkenyan xlarge">
                            <img alt="" src="/icons/director.png" width="32px" className="right6" style={{verticalAlign: "bottom"}}/>
                            <Translate>Директор</Translate>
                        </p>
                        <div style={{height: "2px", backgroundColor: "black", width: "75%", margin: "4px 0px 8px 0px"}}/>
                        <SchoolMember id={schoolData.adminUser} name={directorName}/> 
                    </div>}
                    <p className="fmak bold xlarge" style={{marginTop: width >= 650 ? "8px" : "36px"}}><Translate>Про школу</Translate></p>
                    <div style={{height: "2px", backgroundColor: "black", width: "300px", maxWidth: "75%", margin: "4px 0px 8px 0px"}}/>
                    {schoolData && schoolData.description && schoolData.description.length > 0 && <div style={{zIndex: 50}}>
                        <PostMessage msg={schoolData.description} path={path} open={false} sep={false} textStyle="smallTextPost"/>
                    </div>}
                    <img alt="" src="/bkg/decor/dec8.png" style={{position: "absolute", width: "24px", right: "32px",
                        bottom: "2px", filter: "hue-rotate(120deg)"}}/>
                </div>
            </div>
            <div style={{height: "16px"}}/>
        </div>
        <div style={{backgroundColor: "white"}}>
            <img alt="" src="/bkg/decor/dec10.png" style={{position: "absolute", width: "120px", left: "0px",
                top: "0px", transform: "translate(-50%, -100%)", zIndex: 100}}/>
            <div style={{width: "100%", maxWidth: "1024px", margin: "auto"}}>
                {teachers && teachers.length > 0 && <>
                    <p className="fkenyan xlarge top24" style={{marginLeft: "8px"}}>
                        <img alt="" src="/icons/teacher.png" width="32px" className="right6" style={{verticalAlign: "bottom"}}/>
                        <Translate>Викладачі</Translate>
                    </p>
                    <div className="magSection" style={{display: "block", backgroundColor: "#fee6dd"}}>
                        <HorizontalScroll itemSize={264}>
                            {teachers.map((teacher, index) => {
                                return <div key={index} style={{minWidth: "264px", maxWidth: "264px", padding: "16px"}}>
                                    <SchoolMember id={teacher.teacher} name={teacher.name} role={teacher.role}/>
                                </div>
                            })}
                        </HorizontalScroll>
                    </div>
                </>}
                {courses && schoolData && courses.length > 0 && <>
                    <p className="fkenyan xlarge top24" style={{marginLeft: "8px"}}>
                        <img alt="" src="/icons/student.png" width="32px" className="right6" style={{verticalAlign: "bottom"}}/>
                        <Translate>Курси</Translate>
                    </p>
                    <div className="magSection" style={{backgroundColor: "#fef5de"}}>
                        <img alt="" src="/bkg/decor/dec13.png" style={{position: "absolute", right: "-40px",
                            top: "-20px", width: "32px"}}/>
                        <SchoolCourses courses={courses} school={schoolId} schoolName={schoolData.title}/>
                    </div>
                </>}
                {products && products.items.length > 0 && <>
                    <p className="fkenyan xlarge top24" style={{marginLeft: "8px"}}>
                        <img alt="" src="/icons/shop3.png" width="32px" className="right6" style={{verticalAlign: "bottom"}}/>
                        <Translate>Магазин</Translate>
                    </p>
                    <div className="magSection" style={{backgroundColor: "#d9f5ed"}}>
                        <div style={{position: "absolute", left: "-12px", bottom: "20px", width: "24px",
                            height: "24px", backgroundColor: "#ff298a9c", borderRadius: "50%"}}/>
                        <InfiniteScroll
                            dataLength={products.items.length}
                            next={fetchMoreData}
                            hasMore={products.hasMore}
                            loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                            style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "flex-start"}}
                            endMessage={null}>
                            {products.items.map((data, index) => 
                                <Item key={index} data={data}/>)}
                        </InfiniteScroll>
                    </div>
                </>}
            </div>
        </div>
        <div style={{height: "32px"}}/>
        <HomeButton/>
        <div style={{height: "32px"}}>
            <img alt="" src="/bkg/decor/dec5.png" style={{position: "absolute", right: "0px",
                bottom: "0px", width: "64px"}}/>
        </div>
        <Footer/>
    </div>
}

export default School