import { useRef, useState } from "react";
import Button from "react-bootstrap/Button"
import { BsArrowDownSquare, BsArrowUpSquare, BsTrash, BsTypeBold, BsTypeItalic, BsTypeUnderline } from "react-icons/bs";
import { MdOutlineAddBox } from "react-icons/md"
import ContentEditor from "../../../Components/ContentEditor";
import Translate from "../../../AppContext/Translate";

function ItemEditor({index, text, setText, setChanges, last, deleteItem, swapItems}) {
    const editor = useRef(null);

    const execCommand = (evt, cmd) => {
        evt.preventDefault();
        document.execCommand(cmd, false, null);
    }

    return <div style={{width: "100%", display: "flex", margin: "4px 0px"}}>
        <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
            <Button variant="outline-dark" size="sm" onMouseDown={evt => { execCommand(evt, "bold"); }}><BsTypeBold/></Button>
            <Button variant="outline-dark" size="sm" style={{marginTop: "4px"}} onMouseDown={evt => { execCommand(evt, "italic"); }}><BsTypeItalic/></Button>
            <Button variant="outline-dark" size="sm" style={{marginTop: "4px"}} onMouseDown={evt => { execCommand(evt, "underline"); }}><BsTypeUnderline/></Button>
        </div>
        <div style={{flexGrow: 1, padding: "0px 4px"}}>
            <ContentEditor text={text} onTextChange={(txt) => { setText(index, txt); }}
                editor={editor} setCursorPosition={() => {}} flagChanged={setChanges}
                restrictedSanitize={true}/>
        </div>
        <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
            <Button variant="outline-dark" size="sm" onClick={() => { deleteItem(index); }}><BsTrash/></Button>
            <Button variant="outline-dark" style={{marginTop: "4px"}} size="sm" disabled={index === 0} onClick={() => { swapItems(index - 1); }}><BsArrowUpSquare/></Button>
            <Button variant="outline-dark" style={{marginTop: "4px"}} size="sm" disabled={last} onClick={() => { swapItems(index); }}><BsArrowDownSquare/></Button>
        </div>
    </div>
}

function RTItemsEditor({title, items, setItems, setChanges}) {
    const [error, setError] = useState(null);

    const newItem = () => {
        setItems([...items, ""]);
    }

    const removeLastBreakLines = (cadena) => {
        return cadena.replace(/(<br\s*\/?>)+$/gi, '');
    };

    const countLines = (text) => {
        const regexBr = /<br\s*\/?>/gi;
        const n = text.match(regexBr);
        return n ? n.length : 0;
    };

    const totalLength = (items) => {
        return items.reduce((sum, text) => sum + text.length, 0);
    }

    const check = (items) => {
        if (totalLength(items) > 2000) setError("загальна довжина тексту елементів задовга");
        else {
            for (var i = 0; i < items.length; i++) {
                if (countLines(items[i]) > 1) {
                    setError("кожен блок повинен мати не більше двох абзаців");
                    return;
                }
            }
            setError(null);
        }
    }

    const updateText = (index, newText) => {
        const updText = removeLastBreakLines(newText);
        const updatedItems = [...items];
        updatedItems[index] = updText;
        setItems(updatedItems);
        setChanges(true);
        check(updatedItems);
    }

    const deleteItem = (index) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
        check(updatedItems);
    };

    const swapItems = (index) => {
        if (index < 0 || index >= items.length - 1) {
            return;
        }
        const updatedItems = [...items];
        const temp = updatedItems[index];
        updatedItems[index] = updatedItems[index + 1];
        updatedItems[index + 1] = temp;
        setItems(updatedItems);
    };

    return <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
        {title}
        {items.map((text, i) => {
            return <ItemEditor key={i} index={i} text={text} setText={updateText} setChanges={setChanges}
                last={i === items.length - 1} deleteItem={deleteItem} swapItems={swapItems}/>
        })}
        <Button variant="link" onClick={newItem}>
            <MdOutlineAddBox />
        </Button>
        {error && <div style={{backgroundColor: "palevioletred", color: "white", padding: "2px", width: "100%"}}>
            <p><Translate>Помилка</Translate>: <Translate>{error}</Translate></p>
        </div>}
    </div>
}

export default RTItemsEditor