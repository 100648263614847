import { useEffect, useState, useContext, lazy, Suspense } from 'react';
import BeatLoader  from "react-spinners/BeatLoader";
import "video.js/dist/video-js.css";
import { getAPICall, getAPIurl } from './APICall';
import { AppContext } from '../AppContext/AppContext';
import { getExtendedLanguageName } from '../AppContext/Translate';

const RepVideo = lazy(() => import('./RepVideo'));

function Video({name, url, autoplay=false}) {
  const Loading = () => <BeatLoader size={24} color="#0d6efd" />;
  let kind = "application/x-mpegURL";
  const pos = url.lastIndexOf('&kind=');
  if (pos > 0) {
    kind = url.substring(pos + 6);
    url = url.substring(0, pos);  
  }
  const { userId, token } = useContext(AppContext);
  const [subtitles, setSubtitles] = useState(null);
  const play = {
      fill: true,
      fluid: true,
      autoplay: autoplay,
      controls: true,
      preload: "metadata",
      sources: [
      {
          src: url,
          type: kind
      }
      ]
  };
  useEffect(() => {
    const subtitlesSuccess = (data) => {
      var sub = [];
      for (var i = 0; i < data.length; i++) {
        const row = data[i];
        const path = getAPIurl('media/getSubtitle', {path: encodeURIComponent(row["path"]),
          userId: userId, token: encodeURIComponent(token)});
        sub.push({path: path, lang: row["lang"], langName: getExtendedLanguageName(row["lang"])});
      }
      setSubtitles(sub);
    }
    const subtitlesError = (data) => {
      setSubtitles([]);
    }
    getAPICall('media/subtitles', {name: name}, subtitlesSuccess, subtitlesError);
  }, [userId, token, name]);
  
  return ( subtitles === null ? <BeatLoader size={24} color="#0d6efd" /> :
    <Suspense fallback={<Loading />}>
      <RepVideo {...play} subtitles={subtitles} userId={userId} token={token} />
    </Suspense>);
}

export default Video