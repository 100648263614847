import Translate from "../../AppContext/Translate";
import { getAltBackgroundImageColor } from "../Themes/ThemeColors";
import GeneralPackage from "./GeneralPackage";

function GeneralPackages({packages, options, numDays, theme, plansRef, student}) {
    return <div style={{backgroundColor: getAltBackgroundImageColor(theme), color: "white"}} className="top36">
        <p ref={plansRef} className="center bot16 xxxlarge" style={{paddingTop: "24px"}}><Translate>ПАКЕТИ КУРСУ</Translate>:</p>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "flex-start"}}>
            {packages.map((pkg, i) => {
                return <GeneralPackage key={i} index={i} data={pkg} student={student} options={options}
                    numDays={numDays}/>
            })}
        </div>
    </div>
}

export default GeneralPackages