import { useEffect, useRef, useState } from "react";
import { getAPICall } from "../Components/APICall";
import Translate, { formatDate } from "../AppContext/Translate";
import { getAltBackgroundColor } from "./Themes/ThemeColors";
import BeatLoader from "react-spinners/BeatLoader";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import UserPhotoIcon from "../Components/UserPhotoIcon";
import ReactStars from "react-rating-stars-component";
import PostMessage from "../Components/PostMessage";
import MagButton from "../Components/MagButton";

function CourseOpinions({courseId, theme, width, plansRef}) {
    const [state, setState] = useState({items: [], hasMore: true, offset: 0});
    const [loading, setLoading] = useState(false);
    const scrollContainerRef = useRef(null);
    const limit = 16;
    const itemSize = 240;

    const opinionsError = (data) => {
        setState({items: state.items, hasMore: false, offset: state.offset});
        setLoading(false);
    }

    const opinionsSuccess = (data, reset) => {
        if (data && data.length > 0) {
            if (reset)
                setState({
                    items: data,
                    hasMore: data.length >= limit,
                    offset: data.length
                });
            else
                setState({
                    items: state.items.concat(data),
                    hasMore: data.length >= limit,
                    offset: state.offset + data.length
                });
            setLoading(false);
        } else opinionsError(data);
    }

    const fetchMoreData = (reset=false) => {
        setLoading(true);
        getAPICall('course/opinions', {courseId: courseId, offset: reset ? 0 : state.offset, limit: limit},
            (data) => { opinionsSuccess(data, reset); }, opinionsError);
    }

    useEffect(() => {
        fetchMoreData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    const scrollByAmount = (amount) => {
        scrollContainerRef.current.scrollBy({ left: amount, behavior: "smooth" });
    };

    const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        if (scrollLeft + clientWidth >= scrollWidth - 5 && !loading) {
            fetchMoreData();
        }
    };

    return  state.items.length > 0 && <div style={{backgroundColor: getAltBackgroundColor(theme), display: "flex", flexWrap: "wrap"}}>
        <img alt="" src="/bkg/decor/heart.png" style={{position: "absolute", right: "24px", top: "24px", width: "84px"}}/>
        <div style={{width: width >= 720 ? "35%" : "100%", color: "white", textAlign: "center"}}>
            <p className="top24 xxxlarge"><Translate>Bідгуки</Translate>:</p>
            <img alt="" src="/bkg/decor/sep1.png" style={{width: "263px"}}/>
            {width >= 720 && <div style={{marginTop: "112px", width: "60%", display: "flex",
                justifyContent: "center", minWidth: "160px"}}>
                <MagButton text="придбати курс" clickEff={{scrollRef: plansRef}}/>
            </div>}
        </div>
        <div style={{display: "flex", alignItems: "center", width: width >= 720 ? "65%" : "100%",
            marginTop: width >= 720 ? "84px" : "24px"}}>
            <button onClick={() => scrollByAmount(-itemSize)}
                style={{position: "absolute", left: "6px", zIndex: 1, backgroundColor: "white",
                        border: "solid #d9d9d8", color: "black", fontSize: "24px", cursor: "pointer",
                        padding: "2px 10px 4px 8px", borderRadius: "50%"}}>
                <FaAngleLeft />
            </button>
            <div ref={scrollContainerRef} onScroll={handleScroll} className="opinionsScroll">
                {state.items.map((op, index) => (
                    <div key={index} style={{minWidth: (itemSize - 20) + "px", maxWidth: (itemSize - 20) + "px",
                        margin: "10px", padding: "12px", border: "solid black thin", minHeight: "200px",
                        backgroundColor: "#f0f0f0", borderRadius: "5px"}}>
                        <UserPhotoIcon user={op.user} userName={op.name}/>
                        <p className="bold top4">{op.name}</p>
                        <ReactStars count={5} size={22} value={op.score} edit={false} isHalf={true}/>
                        <div className="top4 bot16">
                            <PostMessage msg={op.message} open={false} textStyle="smallTextPost" sep={false}/>
                        </div>
                        <p style={{fontSize: "small", color: "gray", position: "absolute", right: "10px",
                            bottom: "4px"}}>{formatDate(op.firstUpdate)}</p>
                    </div>
                ))}
                {loading && (
                    <div style={{ padding: "20px", textAlign: "center" }}><BeatLoader/></div>
                )}
            </div>
            <button onClick={() => scrollByAmount(itemSize)}
                style={{position: "absolute", right: "6px", zIndex: 1, backgroundColor: "white",
                    border: "solid #d9d9d8", color: "black", fontSize: "24px", cursor: "pointer",
                    padding: "2px 10px 4px 8px", borderRadius: "50%"}}>
                <FaAngleRight />
            </button>
        </div>
        {width < 720 && <div style={{marginTop: "24px", width: "60%", display: "flex",
            justifyContent: "center", minWidth: "160px", marginBottom: "24px"}}>
            <MagButton text="придбати курс" clickEff={{scrollRef: plansRef}}/>
        </div>}
    </div>
}

export default CourseOpinions