import { BsArrowDownSquare, BsArrowUpSquare, BsTrash } from "react-icons/bs";
import DropdownImageSelect from "../../../Components/DropdownImageSelect";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import { MdOutlineAddBox } from "react-icons/md";

function CourseIdeas({ideas, setIdeas, setChanges}) {
    const numIdeas = 24;
    var ideasImages = [];
    for (var i = 1; i <= numIdeas; i++) ideasImages.push({id: i, name: "/bkg/ideas/" + i + ".png"});

    const updateIdeaIcon = (idIcon, idIdea) => {
        var newIcon = idIcon >= 0 && idIcon < ideasImages.length ? idIcon + 1 : -1;  
        const newIdeas = ideas.map((idea, i) => {
            if (i === idIdea) return {icon: newIcon, idea: idea.idea};
            else return idea;
        });
        setIdeas(newIdeas);
        setChanges(true);
    }

    const updateIdea = (event, idIdea) => {
        const newIdeas = ideas.map((idea, i) => {
            if (i === idIdea) return {icon: idea.icon, idea: event.target.value};
            else return idea;
        });
        setIdeas(newIdeas);
        setChanges(true);
    }

    const moveUpIdea = (idIdea) => {
        const newIdeas = ideas.map((idea, i) => {
            if (i === idIdea - 1) return ideas[idIdea];
            else if (i === idIdea) return ideas[idIdea - 1];
            else return idea;
        });
        setIdeas(newIdeas);
        setChanges(true);
    }

    const deleteIdea = (i) => {
        var copy = [...ideas];
        copy.splice(i, 1);
        setIdeas(copy);
        setChanges(true);
    }

    const addIdea = () => {
        setIdeas([...ideas, {icon: -1, idea: ''}]);
        setChanges(true);
    }

    return <div>
        {ideas.map((idea, i) => {
            return <div key={i}>
                <DropdownImageSelect images={ideasImages} invert={true} onChange={(id) => { updateIdeaIcon(id, i); }}/>
                <div style={{display: "flex", alignItems: "flex-start", marginTop: "4px"}}>
                    <img alt='' src={idea.icon > 0 ? ideasImages[idea.icon-1].name : "/courses/nocover.jpg"}
                        style={{height: "40px", marginRight: "16px", filter: idea.icon > 0 ? "invert(1)" : "none"}}/>
                    <Form.Control value={idea.idea ? idea.idea : ''} type="text" id={"inputIdea" + i}
                        onChange={(e) => { updateIdea(e, i); }} />
                    <Button variant="light" size="sm" disabled={i === 0} style={{marginTop: "2px"}}
                        onClick={() => { moveUpIdea(i); }}><BsArrowUpSquare/></Button>
                    <Button variant="light" size="sm" disabled={i === ideas.length - 1} style={{marginTop: "2px"}}
                        onClick={() => { moveUpIdea(i+1); }}><BsArrowDownSquare/></Button>
                    <Button variant="light" size="sm" style={{marginTop: "2px"}}
                        onClick={() => { deleteIdea(i); }}><BsTrash/></Button>
                </div>
            </div>
        })}
        <Button variant="link" onClick={addIdea}>
            <MdOutlineAddBox/>
        </Button>
    </div>
}

export default CourseIdeas