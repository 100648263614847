import Footer from "../Components/Footer"
import HomeButton from "../Components/HomeButton"
import MagBanner from "../Components/MagBanner"
import CourseList from "../Lists/CourseList"
import Menu from "../Menu/Menu"

function Courses() {
    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="KУРСИ" icon="/icons/courses.png"/>
        <div style={{padding: "0px 24px 24px 24px", backgroundColor: "white"}}>
            <CourseList filter={{show: true, school: 0, cat: 0, lang: ''}}/>
            <HomeButton/>
        </div>
        <Footer/>
    </div>
}

export default Courses