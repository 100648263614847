import MagButton from "../../Components/MagButton"
import { getAltBackgroundColor } from "../Themes/ThemeColors"

function GeneralAudience({width, theme, lines, plansRef}) {
    return <div style={{width: width >= 728 ? "50%" : "100%", backgroundColor: getAltBackgroundColor(theme),
        color: "black", display: "flex", flexDirection: "column"}} className="pad24">
        {lines && lines.map((line, i) => {
            return <div key={i} style={{display: "flex", alignItems: "flex-start"}}>
                <img alt="" src={"/bkg/decor/bullet" + (theme + 1) + ".png"} width="48px" className="bot16"/>
                <div style={{textAlign: "left", marginLeft: "18px"}}
                    className="bot16">{line}</div>
            </div>
        })}
        <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
            <div style={{width: "280px", zIndex: 100}}>
                <MagButton text="придбати курс" clickEff={{scrollRef: plansRef}}/>
            </div>
        </div>
        {width >= 720 && <img alt="" src="/bkg/decor/dec16.png" style={{position: "absolute",
            top: "-40px", width: "128px", right: "-10px"}}/>}
        {width >= 720 && <img alt="" src="/bkg/decor/m.png" style={{position: "absolute", bottom: "0px", left: "-30px",
                width: "30%", maxWidth: "214px", minWidth: "90px", transform: "translateX(-50%)"}}/>}
    </div>
}

export default GeneralAudience