import Button from "react-bootstrap/Button"
import Translate, { translate, translateWithNumber } from "../../../AppContext/Translate"
import { BiSolidCloudUpload, BiTrash } from "react-icons/bi"
import AttFile from "../../../Components/AttFile";
import { confirm } from "../../../Components/ConfirmFunction";
import { getAPIurl } from "../../../Components/APICall";
import { useEffect, useState } from "react";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";

function VideoExists({selectedVideo, setSelectedVideo, dicLang, setChanges}) {
    const [file, setFile] = useState(null);

    useEffect(() => {
        const getFile = async (videoId) => {
            var url = getAPIurl('media/videoType', {name: videoId});
            let kind;
            try {
                kind = await axios.get(url, {headers: {Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8"}});
                kind = kind.data["kind"];
            } catch (e) { 
                kind = 'video/mp4'; 
            }
            url = getAPIurl('media/hls', {name_i:  videoId}) + '&kind=' + kind;
            setFile(new AttFile('v', videoId.toString(), {url: url}));
        }
        if (selectedVideo.kind === "cloud") getFile(selectedVideo.id);
    }, [selectedVideo]);

    const deleteVideo = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити відео презентації? Пам’ятайте, що курси завжди повинні мати презентаційне відео.Ви впевнені, що бажаєте активувати курс?', dicLang)})) {
            if (selectedVideo.kind === "local") setSelectedVideo(null);
            else {
                setSelectedVideo({...selectedVideo, action: "delete"});
                setChanges(true);
            }
        }
    }

    return <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        {selectedVideo.kind === "local" ?  selectedVideo.file.getPreview() :
            (file ? file.getPreview() : <BeatLoader/>)}
        {selectedVideo.action !== "delete" ?
            <Button className="top4" size="sm" variant="secondary" onClick={deleteVideo}>
                <BiTrash className="right6"/><Translate>Видалити</Translate>
            </Button> :
            <p style={{color: "indianred"}}><Translate>Коли ви збережете зміни, відео буде видалено</Translate></p>
        }
    </div>
}

function NoVideo({dicLang, maxVideoSize, setSelectedVideo, setChanges}) {
    const showError = (msg) => {
        alert(translate("Помилка", dicLang) + ": " + msg);
    }

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        const size = file.size / 1048576; 
        const ext = file.name.split('.').pop().toLowerCase();
        if (ext !== 'mp4') {
            showError(translate("не вдалося завантажити файл", dicLang));
        } else if (size > maxVideoSize) {
            showError(translateWithNumber("завеликий розмір відео (максимум * Мб)", maxVideoSize, dicLang));
        } else {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    setSelectedVideo({kind: "local", file: new AttFile('v', file.name, {size: size, buffer: result}),
                        action: "upload"});
                    setChanges(true);
                } else {
                    showError(translate("не вдалося завантажити файл", dicLang));
                }
            }
            fileReader.readAsDataURL(file);
        }
    };

    return <>
        <input type="file" accept="video/mp4"  id="video-upload" style={{ display: 'none' }}
            onChange={handleVideoChange}/>
        <Button className="top12" onClick={() => document.getElementById('video-upload').click()}>
            <BiSolidCloudUpload className="right6"/>Виберіть відео
        </Button>
    </>
}

function UploadVideo({selectedVideo, setSelectedVideo, setChanges, dicLang, maxVideoSize=450}) {
    return <div className="center" style={{width: "100%"}}>
        <p><Translate>Відео презентація курсу</Translate></p>
        {selectedVideo ?
            <VideoExists selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} 
                dicLang={dicLang} setChanges={setChanges}/> :
            <NoVideo dicLang={dicLang} maxVideoSize={maxVideoSize} setSelectedVideo={setSelectedVideo}
                setChanges={setChanges}/>}
    </div>
}

export default UploadVideo