import { useEffect, useRef } from "react";
import sanitizeHtml from "sanitize-html";

function ContentEditor({text, onTextChange, setCursorPosition, flagChanged=null, editor=null, sty={},
    restrictedSanitize=false}) {
    const textRef = useRef(text);
    
    const getTextPosition = (node, offset) => {
        const range = document.createRange();
        range.selectNodeContents(editor.current);
        range.setEnd(node, offset);
        return range.toString().length;
    };
    
    const handleBlur = () => {
        if (window.getSelection) {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const startPosition = getTextPosition(range.startContainer, range.startOffset);
                const endPosition = getTextPosition(range.endContainer, range.endOffset);

                setCursorPosition({
                    cursorPosition: endPosition,
                    selectionStart: startPosition,
                    selectionEnd: endPosition
                });
            }
        }
        sanitize();
    };

    useEffect(() => {
        textRef.current = text;
    }, [text]);

    const sanitizeConf = {
        allowedTags: restrictedSanitize ? ["b", "i", "u", "br"] : ["b", "i", "u", "ul", "a", "li", "br", "img"],
        allowedAttributes: restrictedSanitize ? {} : { a: ["href"], img: ["alt", "width", "height", "src"] }
    };

    const removeEmojis = (text) => {
        const emojiRegex = /([\u231A-\u231B]|[\u23E9-\u23EC]|[\u23F0-\u23F3]|[\u25FD-\u25FE]|[\u2600-\u27BF]|[\u2B50\u2B55\u2934\u2935\u2B06\u2B07\u2B05\u2B95\u2B1B\u2B1C\u2B50\u2B55]|[\u303D\u3030\u303D]|[\u3297\u3299\u3297\u3299]|[\uD83C-\uDBFF\uDC00-\uDFFF\u200D]|[\uD83D-\uDBFF\uDC00-\uDFFF\u200D]|[\uD83E-\uDBFF\uDC00-\uDFFF\u200D])/g;
        return text.replace(emojiRegex, '');
    };

    const sanitize = () => {
        if (editor.current) {
            const text = editor.current.innerHTML.replace(/<div>/g, '<br/>').replace(/<\/div>/g, '');
            const sanitizedText = sanitizeHtml(removeEmojis(text), sanitizeConf);
            onTextChange(sanitizedText);
        }
    }

    return <div className="ChatTextArea" style={sty}>
        <div
            ref={editor}
            contentEditable
            onBlur={handleBlur}
            onInput={flagChanged}
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ border: '1px solid #dee2e6', padding: '5px', width: "100%" }}
        />
    </div>
}

export default ContentEditor