import CourseCard from "../Lists/CourseCard"

function SchoolCourses({courses, school, schoolName}) {
    return <div style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "flex-start"}}>
    { courses &&
        courses.map((course, k) => {
            const data = {...course};
            data['schoolId'] = school;
            data['school'] = schoolName;
            return <CourseCard key={k} course={data} top={false}/>
        })
    }
    </div>
}

export default SchoolCourses