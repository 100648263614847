import { useState, lazy, Suspense } from "react";
import Translate from "../AppContext/Translate";
import { HiPhotograph } from 'react-icons/hi';
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const UploadPicture = lazy(() => import('../Components/UploadPicture'));

function SchoolLogo({schoolId, allowChange=false, changeLink, size='med'}) {
    const timestamp = new Date().getTime();
    const [foundImg, setFoundImg] = useState(true);
    const [showChangeLogo, setShowChangeLogo] = useState(false);
    const [logoUrl, setLogoUrl] = useState('/schools/logo' + schoolId + '.png?timestamp=' + timestamp);
    const [number, setNumber] = useState(1);
    var navigate = useNavigate();

    const forceUpdate = () => {
        setNumber(current => current + 1);
        setLogoUrl('/schools/logo' + schoolId + '.png?n=' + number);
        setFoundImg(true);
        if (changeLink) navigate(changeLink);
    };

    return <div style={{display: "flex", alignItems: "flex-start", position: "relative"}}>
        {foundImg ?
            <img className={size + "SchoolLogo"} alt="Logo" src={logoUrl} onError={()=>setFoundImg(false)}/> : 
            <svg className={size + "SchoolLogo"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127 127" width="100%" 
                height="100%" preserveAspectRatio="none">
                <rect width="100%" height="100%" fill="#696969" />
            </svg>
        }
        {allowChange && <>
         <Button variant="secondary" size="sm" className="userButtonCoverChange" onClick={()=>{setShowChangeLogo(true);}}>
            <HiPhotograph size="18" style={{marginRight: "4px", marginTop: "-2px"}}/>
            <Translate>Змінити логотип</Translate>
        </Button>
        <Suspense>
            <UploadPicture show={showChangeLogo} handleClose={()=>{setShowChangeLogo(false);}} width={128} height={128} 
                title="Змінити логотип" apiFnc="school/logo" forceUpdate={forceUpdate} params={{school: schoolId}}
                kind="png"/>
        </Suspense>
        </>
        }
    </div>
}

export default SchoolLogo