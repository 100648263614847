import Menu from "../Menu/Menu"
import { useEffect, useContext, useState } from "react"
import { AppContext } from "../AppContext/AppContext";
import { Navigate } from "react-router-dom";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import Footer from "../Components/Footer";
import Shop from "../Shop/Shop";
import UserProduct from "./UserProduct";
import HomeButton from "../Components/HomeButton";
import MyProfileButton from "../Components/MyProfileButton";
import MagBanner from "../Components/MagBanner";
import CabHeader from "../Cabinet/CabHeader";

function UserProducts() {
    const { userId, userName, token, lang } = useContext(AppContext);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const purchasedItemsOk = (data) => {
            if (data && data.length > 0) {
                setItems(data);
            }
        }
        if (userId !== -1) {
            getAPICall('user/purchasedUniqueItems', {userId: userId, token: encodeURIComponent(token)},
                purchasedItemsOk);
        }
    }, [userId, token, lang]);

    return  userId >= 0 ? <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu />
        <MagBanner title="Мої товари" bkgColor="#fac032" decor={<img alt="" src="/bkg/decor/dec5.png" 
            width="60%" style={{float: "right"}}/>}/>
        <div className="magSection" style={{backgroundColor: "#fef5de", flexDirection: "column"}}>
            <CabHeader user={userId} userName={userName}/>
            <div className="userProfileContent">
            {items.length === 0 ? <>
                <p className="bold"><Translate>Ви ще не купили жодного товару</Translate>.</p>
                <p className="top8 bot16"><Translate>Продукти, які можуть вас зацікавити</Translate>:</p>
                <Shop showMenu={false} bkgColor="#fef5de"/>
            </> :
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center"}}>
                {
                items.map((item, i) => {
                    return <UserProduct key={i} item={item.reference}/>
                })
                }
            </div>
            }
            </div>
        </div>
        <div className="magSection" style={{justifyContent: "space-evenly", flexWrap: "wrap", backgroundColor: "#fef5de"}}>
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div>:
    <Navigate to="/" replace={true} />;
}

export default UserProducts