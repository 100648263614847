import { lazy, Suspense } from 'react';
import Translate from '../../AppContext/Translate';
import HorizontalScroll from '../../Components/HorizontalScroll';
import { getBackgroundImageColor } from './ThemeColors';

const BackgroundImages = lazy(() => import('./BackgroundImages'));

function BackgroundImageSelector({selectedImage, setSelectedImage, colorTheme, setChanges}) {
    const imgs = Array.from({ length: 16 }, (_, index) => index + 1);

    return <div style={{width: "100%"}}>
        <p className='top18'><Translate>Виберіть фонове зображення</Translate>:</p>
        <HorizontalScroll itemSize={120} initPos={Math.max(0, selectedImage * 120 - 12)}>
            {imgs.map((i) => {
                return <div key={i} style={{width: "100px", minWidth: "100px", height: "100px", margin: "9px",
                    border: selectedImage + 1 === i ? "double 2px black" : "double 2px transparent",
                    cursor: "pointer", borderRadius: "6px", display: "flex", justifyContent: "center",
                    alignItems: "center"}} onClick={() => {
                        if (i !== selectedImage + 1) {
                            setSelectedImage(i - 1);
                            setChanges(true);
                        }
                    }}>
                    <Suspense>
                        <BackgroundImages id={i} sty={{color: getBackgroundImageColor(colorTheme), width: "90px", height: "90px"}}/>
                    </Suspense>
                </div>
            })}
        </HorizontalScroll>
    </div>
}

export default BackgroundImageSelector