import Translate from "../AppContext/Translate"
import Button from 'react-bootstrap/Button';
import { TbHomeUp } from "react-icons/tb";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";

function CabTitle({title=null, translate=true}) {
    const popover = (
        <Tooltip><Translate>Повернутися до кабінету</Translate></Tooltip>
    );

    return <div style={{display: "flex", justifyContent: title ? "space-between" : "flex-end", alignItems: "center"}}>
        {title && <h1>{translate ? <Translate>{title}</Translate> : title}</h1>}
        <OverlayTrigger placement="left" delay={{ show: 1000, hide: 400 }} overlay={popover}>
            <Link to="/cabinet/menu">
                <Button variant="outline-dark"><TbHomeUp size="24" style={{marginTop: "-6px"}}/></Button>
            </Link>
        </OverlayTrigger>
    </div>
}

export default CabTitle