import { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../AppContext/AppContext";
import Menu from "../Menu/Menu";
import { Navigate } from "react-router-dom";
import Translate, { formatDate, translate } from "../AppContext/Translate";
import { getAPICall } from "../Components/APICall";
import Footer from "../Components/Footer";
import { IoFootstepsSharp } from "react-icons/io5";
import ProgressBar from 'react-bootstrap/ProgressBar';
import CountUp from "../Components/CountUp";
import { useNavigate } from "react-router-dom";
import { translateWithNumber } from "../AppContext/Translate";
import PostMessage from "../Components/PostMessage";
import { Link } from "react-router-dom";
import HomeButton from "../Components/HomeButton";
import MyProfileButton from "../Components/MyProfileButton";
import MagBanner from "../Components/MagBanner";
import UserPhotoIcon from "../Components/UserPhotoIcon";
import "./Rewards/reward.css";
import TableProgress from "./Rewards/TableProgress";
import { CiWarning } from "react-icons/ci";

const isCompatible = () => {
    // Opera 8.0+
    let isOpera = (typeof window.opr !== "undefined" && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // 360 Browser or other compatible Chrome-based browsers
    let is360 = parseInt(navigator.userAgent.substr(navigator.userAgent.indexOf('Chrome/') + 7, 2)) >= 80;

    // Chrome detection
    let isChromium = window.chrome;
    let winNav = window.navigator;
    let vendorName = winNav.vendor;
    let Opera = typeof window.opr !== "undefined";
    let isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    let isIOSChrome = winNav.userAgent.match("CriOS");
    let itISChrome;
    if (isIOSChrome) {
        // Google Chrome en iOS
        itISChrome = true;
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        Opera === false &&
        isIEedge === false
    ) {
        // Google Chrome
        itISChrome = true;
    } else {
        // No es Google Chrome
        itISChrome = false;
    }
    let itISSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    let isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return isOpera || (itISSafari && isiOS) || itISChrome || is360;
};

function Reward({ item }) {
    const { dicLang } = useContext(AppContext);
    const img = <img alt="" src={item.kind === 0 ? "/icons/discount.png" : "/items/item" + item.item + ".jpg"}
        style={{ height: "96px", border: item.kind === 0 ? "none" : "ridge white" }} />
    return <div style={{
        display: "flex", justifyContent: "flex-start", alignItems: "center",
        backgroundColor: item.rank % 2 === 0 ? "floralwhite" : "ghostwhite",
        marginTop: "16px", padding: "2px 12px 2px 12px", width: "100%"
    }}>
        <div style={{ fontFamily: "Mak", marginRight: "8px", fontSize: "1.2rem" }}><Translate>Рівень</Translate>: {item.rank}</div>
        <div style={{ width: "140px", textAlign: "center", marginRight: "8px" }}>
            {item.kind === 0 ? img : <Link to={"/product/" + item.item}>{img}</Link>}
        </div>
        {item.kind === 0 ?
            <p className="cartItemTitle">{translateWithNumber("*% знижка на наступну покупку", item.discount, dicLang)}</p> :
            <div>
                <PostMessage msg={item.description} open={false} sep={false} textStyle="cartItemTitle" />
                <p><Link to={"/school/" + item.school}>{item.schoolName}</Link></p>
            </div>
        }
    </div>
}

function UserRewards() {
    const { userId, userName, token, lang, dicLang } = useContext(AppContext);
    const [rank, setRank] = useState(null);
    const [stepsDone, setStepsDone] = useState(0);
    const [achivements, setAchivements] = useState(null);
    const [delay, setDelay] = useState(null);
    const [totalNewSteps, setTotalNewSteps] = useState(0);
    const [rewards, setRewards] = useState(null);
    const [textPairs, setTextPairs] = useState(null);
    const navigate = useNavigate();
    const starContainerRef = useRef(null);

    const gameCompatible = isCompatible();

    const achivText = [<Translate>придбано нові курси</Translate>,
    <Translate>виконані домашні завдання</Translate>,
    <Translate>нові відгуки про курси</Translate>,
    <Translate>завершено нові курси</Translate>];
    const mult = [3, 1, 1, 2];

    const achivementsSuccess = (data) => {
        if (data !== null) {
            var v = [0, 0, 0, 0];
            var total = 0;
            var d = [];
            var w = 2;
            for (var i = 0; i < data.length; i++) {
                const row = data[i];
                const kind = row.kind - 1;
                if (kind >= 0 && kind < v.length) {
                    v[kind] = row.value;
                }
            }
            for (i = 0; i < v.length; i++) {
                d.push(w);
                if (v[i] > 0) w += 2;
                total += v[i] * mult[i];
            }
            setTotalNewSteps(counter => counter + total);
            setAchivements(v);
            setDelay(d);
        }
    }

    const rankSuccess = (data) => {
        if (data && data.length > 0) {
            setTotalNewSteps(data[0].remainingSteps);
            setRank(data[0]);
            var steps = data[0].stepsDone;
            if (steps > 100) steps = 100;
            setStepsDone(steps);
            getAPICall('user/achivements', {
                userId: userId, token: encodeURIComponent(token),
                date: data[0].lastCheck
            }, achivementsSuccess);
        }
    }

    const rewardsSuccess = (data) => {
        if (data && data.length > 0)
            setRewards(data)
    }

    useEffect(() => {
        if (userId >= 0) {
            getAPICall('user/rank', { user: userId }, rankSuccess);
            getAPICall('game/rewards', { userId: userId, token: encodeURIComponent(token), lang: encodeURIComponent(lang) },
                rewardsSuccess);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token, lang]);

    useEffect(() => {
        if (starContainerRef.current) {
            const small = starContainerRef.current.offsetWidth < 150;
            const stars = 24;
            const sparkle = 20;
            const createStar = (size) => {
                const star = document.createElement('div');
                star.classList.add('shine', size);
                const radius = 50;
                const angle = Math.random() * 2 * Math.PI;
                const r = Math.sqrt(Math.random()) * radius;
                const x = r * Math.cos(angle);
                const y = r * Math.sin(angle);
                star.style.top = `${50 + y}%`;
                star.style.left = `${50 + x}%`;
                star.style.animationDelay = `${Math.random() * sparkle}s`;
                starContainerRef.current.appendChild(star);
            }
            for (let i = 0; i < stars; i++) {
                if (i % 2 === 0) createStar('small');
                else if (i % 3 === 0) createStar('medium');
                else if (!small) createStar('large');
            }
        }
    }, [starContainerRef, rank]);

    useEffect(() => {
        if (achivements && rank && delay) {
            var pairs = [{ text: translate('невикористані попередні кроки', dicLang), mult: 0, steps: rank.remainingSteps }];
            for (let i = 0; i < achivements.length; i++) {
                const value = achivements[i];
                pairs.push({ text: achivText[i], mult: mult[i], steps: value, delay: delay[i] });
            }
            setTextPairs(pairs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achivements, dicLang, rank, delay]);

    const goToMap = () => {
        navigate("/game");
    }

    return userId >= 0 ?
        <div className='mainContainer' style={{ backgroundColor: "white" }}>
            <Menu />
            <MagBanner title="Мої винагороди" bkgColor="#12c191"
                decor={<img alt="" src="/bkg/decor/dec18.png" width="25%" style={{ float: "right", marginRight: "12px" }} />} />
            <div className="magSection" style={{ backgroundColor: "#fef5de", flexDirection: "column" }}>
                <div className="pad12" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <UserPhotoIcon user={userId} userName={userName} big={true} />
                    <div style={{ marginLeft: "12px", width: "100%" }}>
                        <h2>{userName}</h2>
                        <div style={{ height: "1px", backgroundColor: "black", width: "75%" }} />
                        {rank && <div ref={starContainerRef} style={{
                            position: "absolute", zIndex: 100, width: "30%",
                            right: "-20px", minWidth: "106px", top: "25%", maxWidth: "220px"
                        }}>
                            <img src={"/icons/rank" + rank.rank + ".png"} alt="" style={{
                                width: "100%",
                                filter: "drop-shadow(2px 2px 1px #00000042)"
                            }} />
                        </div>}
                    </div>
                </div>
                <div className="magSection" style={{
                    backgroundColor: "white", width: "100%", maxWidth: "800px",
                    margin: "auto", flexDirection: "column", alignItems: "flex-start"
                }}>
                    {rank && <>
                        <div className="magSection bold fkenyan large" style={{ backgroundColor: "#d9f5ed" }}>
                            <Translate>Рівень</Translate>: {rank.rank + 1}
                        </div>
                        <div className="pad12" style={{ width: "100%" }}>
                            <p><span style={{ fontWeight: "600" }}><Translate>Остання перевірка</Translate>:</span> {formatDate(rank.lastCheck)}</p>
                            <p className="bot8 top18"><IoFootstepsSharp size="36px" /> <span style={{ fontWeight: "600" }}><Translate>Кількість зроблених кроків</Translate>:</span>
                                <span style={{ marginLeft: "8px", fontSize: "large" }}>{rank.stepsDone}</span></p>
                            <ProgressBar striped variant="primary" min={0} max={63} now={stepsDone} label={`${Math.round(63 / stepsDone)}%`} />
                        </div>
                    </>}
                </div>
                <div className="magSection" style={{
                    backgroundColor: "white", width: "100%", maxWidth: "800px",
                    margin: "8px auto", flexDirection: "column", alignItems: "center"
                }}>
                    <p className="fkenyan xlarge"><Translate>Доступні нові кроки</Translate>:</p>
                    <p className="small"><Translate>За кожну активність нараховуються кроки, які можна обміняти на подарунки</Translate>.</p>
                    <div className="top18 pad12"
                        style={{ border: "thin black solid", width: "520px", maxWidth: "100%", borderRadius: "8px" }}>
                        {textPairs && <TableProgress textPairs={textPairs} />}
                    </div>
                    <p className="top24 fkenyan"><Translate>Кількість кроків</Translate></p>
                    {delay && <>
                        <div style={{
                            fontSize: "xx-large", backgroundColor: "#fee6dd", width: "fit-content",
                            margin: "8px auto", padding: "2px 24px 2px 24px", borderRadius: "12px", boxShadow: "2px 2px 2px #00000021"
                        }}>
                            <CountUp delay={2} end={totalNewSteps} duration={5} />
                        </div>
                        <div style={{ margin: "24px auto 8px auto" }} className="center">
                            <img alt="Отримати подарунки" src="/icons/map.png" className="imgButton"
                                style={{ width: "302px", maxWidth: "50vw" }} onClick={goToMap} />
                            {totalNewSteps > 0 && stepsDone < 63 && <p className="fkenyan large top4 blackTextHover"
                                onClick={goToMap}>
                                <Translate>Отримати подарунки</Translate>
                            </p>}
                            {!gameCompatible && <p className="small top4" style={{ color: "#ef9f80" }}>
                                <CiWarning style={{ margin: "-3px 3px 0px 0px" }} />
                                <Translate>Ваш браузер може бути несумісний. Якщо це не працює для вас, спробуйте це в більш сучасному браузері.</Translate>
                            </p>}
                        </div>
                    </>}
                </div>
                {rewards && <div className="magSection" style={{
                    backgroundColor: "white", width: "100%", maxWidth: "800px",
                    margin: "8px auto", flexDirection: "column", alignItems: "flex-start"
                }}>
                    <p className="fkenyan xlarge"><Translate>Отримані винагороди</Translate>:</p>
                    {rewards.map((item, i) => {
                        return <Reward key={i} item={item}/>
                    })}
                </div>}
            </div>
            {/*
        
            </div>
            {rewards && <div>
                <hr/>
                <h4><Translate>Отримані винагороди</Translate>:</h4>
                {rewards.map((item, i) => {
                    return <Reward key={i} item={item}/>
                })
                }
            </div>}
            </> :
            <BeatLoader/>
            }
        </Container>*/}
            <div style={{
                display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
                backgroundColor: "white", margin: "8px", borderRadius: "4px"
            }} className="pad12">
                <div style={{ margin: "4px" }}><MyProfileButton /></div>
                <div style={{ margin: "4px" }}><HomeButton /></div>
            </div>
            <Footer />
        </div> :
        <Navigate to="/" replace={true} />
}

export default UserRewards