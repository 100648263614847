import { useState } from "react";
import { getAPIurl } from "../../Components/APICall";
import MagButton from "../../Components/MagButton";
import { getTitleBackgroundColor } from "../Themes/ThemeColors";

function GeneralTeacher({teacher, theme, routeChange}) {
    const [foundImg, setFoundImg] = useState(true);
    const url = getAPIurl('media/userPhoto', {user: teacher.teacher});

    return <div style={{margin: "8px"}}>
        <img alt={teacher.name} src={foundImg ? url : "/icons/nouser.png"} onError={()=>setFoundImg(false)}
            style={{width: "256px", borderTopRightRadius: "48px"}}/>
        <div style={{display: "flex", marginTop: "8px", alignItems: "center"}}>
            <div style={{width: "176px"}}>
                <p className="xlarge bold">{teacher.name}</p>
            </div>
            <div style={{width: "80px"}}>
                <MagButton text={<img alt="->" src="/bkg/arrowr.png" width="38px"/>} translate={false}
                    bkgColor={getTitleBackgroundColor(theme)} addStyle={{padding: "8px 16px"}}
                    clickEff={{customFnc: () => { routeChange("/profile/" + teacher.teacher); }}}/>
            </div>
        </div>
    </div>
}

export default GeneralTeacher