export function getBackgroundImageColor(theme) {
    if (theme === 0) return "#bce3d1";
    if (theme === 1) return "#c8a872";
    return "#faddd9";
}

export function getTitleBackgroundColor(theme) {
    if (theme === 0) return "#478b7a";
    if (theme === 1) return "#352120";
    return "#664678";
}

export function getAltBackgroundColor(theme) {
    if (theme === 0) return "#bce3d1";
    if (theme === 1) return "#b4883e";
    return "#fc7cb6";
}

export function getTextColor(theme) {
    if (theme === 0) return "#352120";
    if (theme === 1) return "#352120";
    return "#664678";
}

export function getAltBackgroundImageColor(theme) {
    if (theme === 0) return "#67a392";
    if (theme === 1) return "#432c23";
    return "#7f648e";
}

export function getMatterColor(theme, index) {
    if (index === 1) {
        if (theme === 0) return "#bce3d1";
        if (theme === 1) return "#b4883e";
        return "#fc7cb6";
    }
    if (index === 2) {
        if (theme === 0) return "#478b7a";
        if (theme === 1) return "#352120";
        return "#664678";
    }
    return "#ff2f43";
}

export function getSoftBackgroundColor(theme) {
    if (theme === 0) return "#eff8f4";
    if (theme === 1) return "#ede3d1";
    return "#fee0ee";
}