import './bootstrap.min.css';
import './App.css';
import { lazy, Suspense } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import AppContextProvider from './AppContext/AppContext';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Home from './Home/Home';
import Courses from './Course/Courses';
import Category from './Course/Category';
import Course from './Course/Course';
import Logout from './Menu/Login/Logout';
import RecoverPassword from './Menu/Login/RecoverPassword';
import ValidateEmail from './Menu/Login/ValidateEmail';
import ChangePassword from './Menu/Login/ChangePassword';
import UserProfile from './User/UserProfile';
import UserCourses from './User/UserCourses';
import UserMessage from './User/UserMessage';
import Cabinet from './Cabinet/Cabinet';
import CabSchool from './Cabinet/School/CabSchool';
import CabCourses from './Cabinet/Courses/CabCourses';
import CabStudents from './Cabinet/Students/CabStudents';
import CabPlan from './Cabinet/Plan/CabPlan';
import CabinetAdmin from './Cabinet/Admin/CabinetAdmin';
import CabReport from './Cabinet/Report/CabReport';
import PublicProfile from './User/PublicProfile';
import CitySearch from './Lists/CitySearch';
import PublicProfileActivity from './User/PublicProfileActivity';
import PublicProfileCourses from './User/PublicProfileCourses';
import PublicProfileProjects from './User/PublicProfileProjects';
import Cart from './Cart/Cart';
import Product from './Shop/Product';
import ManageGroup from './Cabinet/Groups/ManageGroup';
import Shop from './Shop/Shop';
import Projects from './Course/Projects/Projects';
import CourseProjects from './Course/Projects/CourseProjects';
import Certificate from './Certificate/Certificate';
import DiplomaValidate from './Certificate/DiplomaValidate';
import UserOrders from './User/UserOrders';
import UserProducts from './User/UserProducts';
import School from './School/School';
import PurchaseDone from './Cart/PurchaseDone';
import UserRewards from './User/UserRewards';
import UserPresent from './User/UserPresent';
import Contract from './Terms/Contract';
import Privacy from './Terms/Privacy';
import Cookies from './Terms/Cookies';
import PublicProfileTeacher from './User/PublicProfileTeacher';
import CabShop from './Cabinet/School/CabShop';
import PublicProfileProducts from './User/PublicProfileProducts';
import SchoolList from './School/SchoolList';
import Categories from './Lists/Categories';
import Teachers from './Lists/Teachers';
import Services from './Services/Services';

const Game = lazy(() => import('./Game/Game'));

function LoadPage() {
  const loc = useLocation().pathname;
  const sep = loc.indexOf('/', 1)
  const link = sep > 0 && sep < loc.length - 1 ? loc.substring(sep) : '/'
  return <Navigate to={link} replace={true} />
}

function App() {
  const Loading = () => <BeatLoader size={18} color="#0d6efd" />;

  return (
  <AppContextProvider>
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="courses" element={<Courses />}/>
          <Route path="category/:id" element={<Category />}/>
          <Route path="user/profile" element={<UserProfile />}/>
          <Route path="user/courses" element={<UserCourses />}/>
          <Route path="user/messages" element={<UserMessage />}/>
          <Route path="cabinet/menu" element={<Cabinet />}/>
          <Route path="cabinet/school" element={<CabSchool />}/>
          <Route path="cabinet/shop" element={<CabShop />}/>
          <Route path="cabinet/courses" element={<CabCourses />}/>
          <Route path="cabinet/courses/:id" element={<CabCourses />}/>
          <Route path="cabinet/students" element={<CabStudents />}/>
          <Route path="cabinet/plan/:id" element={<CabPlan />}/>
          <Route path="cabinet/admin" element={<CabinetAdmin />}/>
          <Route path="cabinet/report" element={<CabReport />}/>
          <Route path="cabinet/report/:id" element={<CabReport />}/>
          <Route path="profile/:id" element={<PublicProfile />}/>
          <Route path="profile/activity/:id" element={<PublicProfileActivity />}/>
          <Route path="profile/courses/:id" element={<PublicProfileCourses />}/>
          <Route path="profile/teaches/:id" element={<PublicProfileTeacher />}/>
          <Route path="profile/projects/:id" element={<PublicProfileProjects />}/>
          <Route path="profile/products/:id" element={<PublicProfileProducts />}/>
          <Route path="cart" element={<Cart/>}/>
          <Route path="product/:id" element={<Product />}/>
          <Route path="course/:id" element={<Course />}/>
          <Route path="students/:id" element={<ManageGroup/>}/>
          <Route path="shop" element={<Shop/>}/>
          <Route path="projects" element={<Projects/>}/>
          <Route path="projects/:id" element={<CourseProjects />}/>
          <Route path="certificate/:id" element={<Certificate />}/>
          <Route path="diploma/validate" element={<DiplomaValidate />}/>
          <Route path="user/orders" element={<UserOrders />}/>
          <Route path="user/orders/:id" element={<UserOrders />}/>
          <Route path="user/products" element={<UserProducts />}/>          
          <Route path="school/:id" element={<School />}/>
          <Route path="user/rewards" element={<UserRewards />}/>
          <Route path="user/present" element={<UserPresent />}/>
          <Route path="game" element={<Suspense fallback={<Loading />}><Game/></Suspense>}/>
          <Route path="purchase/done/:id" element={<PurchaseDone />}/>
          <Route path="purchase/done" element={<PurchaseDone />}/>
          <Route path="city/:id" element={<CitySearch/>}/>
          <Route path="user/validate" element={<ValidateEmail />}/>
          <Route path="user/recover" element={<RecoverPassword />}/>
          <Route path="user/logout" element={<Logout />}/>
          <Route path="user/newpass" element={<ChangePassword />}/>
          <Route path="terms" element={<Contract />}/>
          <Route path="privacy" element={<Privacy />}/>
          <Route path="cookies" element={<Cookies />}/>
          <Route path="schools" element={<SchoolList />}/>
          <Route path="categories" element={<Categories />}/>
          <Route path="teachers" element={<Teachers />}/>
          <Route path="services" element={<Services />}/>
          <Route path="login" element={<Home login={true} />}/>
          <Route path="load/*" element={<LoadPage />}/>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  </AppContextProvider>);
}
  
export default App
