import { useContext, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Translate, { translate } from '../../AppContext/Translate'
import { AppContext } from '../../AppContext/AppContext';
import { getAPICall, postAPICall } from '../../Components/APICall';
import { MediaPath } from '../../Components/MediaPath';
import UserPhotoIcon from '../../Components/UserPhotoIcon';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { confirm } from '../../Components/ConfirmFunction';
import WriteHelper from '../../Components/WriteHelper';
import { processLines } from '../../Course/GeneralCourseInfo';

function CabCourseApproval({course}) {
    var { lang, dicLang, userId, token } = useContext(AppContext);
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState([]);
    const [courseLang, setCourseLang] = useState('');
    const [userName, setUserName] = useState('?');
    const [comment, setComment] = useState(course.adminComment === null ? "" : course.adminComment);
    let navigate = useNavigate(); 

    useEffect(() => {
        getAPICall('user/name', {user: course.user}, (data) => {
            if (data && data.length === 1) {
                setUserName(data[0].name);
            }
        });
        getAPICall('course/data', {course: course.course, lang: lang}, (data) => {
            if (data && data.length === 1) {
                setTitle(data[0].title);
                setCourseLang(data[0].lang);
                setDesc(processLines(data[0].description));
            }
        });
    }, [course.course, course.user, lang]);

    const pic = "/courses/cover" + course.course + '.jpg';
    var path = new MediaPath(MediaPath.kind_openMediaCourse);
    path.add(course.course);
    path.addStr(courseLang + '/desc');

    const commentChange = (event) => {
        setComment(event.target.value);
    }

    const approveCourse = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що бажаєте активувати курс?', dicLang)})) {
            postAPICall('admin/activateCourse', {course: course.course, userId: userId, token: token, 
                courseOwner: course.user, comment: WriteHelper.filterSimpleText(comment)},
                (data) => {
                    navigate('/load/cabinet/admin');
                },
                (data) => {
                    alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
                });
        }
    }

    const rejectCourse = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що не хочете, щоб курс був активований?', dicLang)})) {
            postAPICall('admin/rejectCourse', {course: course.course, userId: userId, token: token, courseOwner: course.user,
                comment: WriteHelper.filterSimpleText(comment)},
                (data) => {
                    navigate('/load/cabinet/admin');
                },
                (data) => {
                    alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
                });            
        }
    }

    return <Accordion style={{marginTop: "8px", marginBottom: "8px"}}>
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                <div>
                    <h4><Translate>Надійшов запит на активацію курсу</Translate></h4>
                    <div style={{display: "flex", alignItems: "flex-start", justifyContent: "left",
                        marginTop: "14px", flexWrap: "wrap"}}>     
                        <img className="medSchoolLogo" style={{marginRight: "16px", marginBottom: "0px", width: "163px"}}
                            alt="" src={pic} width="100%" onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/courses/nocover.jpg";
                        }} />
                        <p className='large' style={{marginTop: "16px"}}>{title}</p>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <div className='cabSchoolCard' style={{maxWidth: "600px", margin: "auto"}}>
                    {desc.length > 0 &&
                        <ul>
                            {desc.map((line, i) => {
                                return <li key={i}>{line}</li>
                            })}
                        </ul>
                    }
                    <div style={{textAlign: "center"}}>
                        <Link to={"/course/" + course.course}>
                            <Button variant='outline-primary'><Translate>Дивитися курс</Translate></Button>
                        </Link>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", marginTop: "16px"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <UserPhotoIcon user={course.user} userName={userName} big={true}/>
                        <p style={{fontWeight: "600"}}>{userName}</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly"}}>
                        <Button variant="primary" onClick={approveCourse}><Translate>Прийняти</Translate></Button>
                        <Button variant="secondary" onClick={rejectCourse}><Translate>Скасувати</Translate></Button>
                    </div>
                </div>
                <Form>
                    <Form.Group className="mb-3" controlId={"tac" + course.course}>
                        <Form.Label><Translate>Додайте коментар</Translate>:</Form.Label>
                        <Form.Control as="textarea" rows={3} defaultValue={comment} maxLength="1000" onChange={commentChange} />
                    </Form.Group>
                </Form>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

export default CabCourseApproval