import Dropdown from 'react-bootstrap/Dropdown';
import Translate from '../AppContext/Translate';
import { CiImageOff } from 'react-icons/ci';

function DropdownImageSelect({images, onChange, title='Виберіть зображення', clear=true, invert=false}) {
    
    const itemSelected = (eventkey, event) => {
        onChange(event.target.id - 1);
    }

    return <Dropdown onSelect={itemSelected} style={{width: "fit-content"}}>
        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <Translate>{title}</Translate>
        </Dropdown.Toggle>
        <Dropdown.Menu variant={invert ? "dark" : "light"} style={{maxHeight: "320px", overflowY: "scroll"}}>
            {clear && 
                <Dropdown.Item key={0} id={0} style={{textAlign: "center", color: invert ? "white" : "black" }}>
                    <CiImageOff size="28"/>
                </Dropdown.Item>}
            {images.map((img, i) => {
                return <Dropdown.Item key={i+1} id={i+1} style={{backgroundImage: 'url("' + img.name + '")',
                    backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center",
                    height: "42px", margin: "6px 0px 6px 0px"}}/>
            })}
        </Dropdown.Menu>
    </Dropdown>
}

export default DropdownImageSelect