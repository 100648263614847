import { lazy, Suspense } from "react";
import Translate from "../../AppContext/Translate"
import { getAltBackgroundImageColor, getTitleBackgroundColor } from "../Themes/ThemeColors"
import MagButton from "../../Components/MagButton";

const BackgroundImages = lazy(() => import('../Themes/BackgroundImages'));

function GeneralGoals({theme, lines, width, backImage, plansRef}) {
    return <div style={{backgroundColor: getTitleBackgroundColor(theme), color: "white", display: "flex"}}
        className="pad24">
        {width >= 720 && <div style={{width: "40%", display: "flex", alignItems: "center"}}>
            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Suspense>
                    <BackgroundImages id={backImage + 1} sty={{color: getAltBackgroundImageColor(theme),
                        width: "100%", height: "auto", maxWidth: "500px"}}/>
                </Suspense>
                <div style={{width: "280px", display: "flex", justifyContent: "flex-end"}}>
                    <MagButton text="хочу навчитися" clickEff={{scrollRef: plansRef}}/>
                </div>
            </div>
        </div>}
        <div style={{width: width < 720 ? "100%" : "60%"}}>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <div style={{width: "400px", maxWidth: "100%", marginRight: width >= 1200 ? "16%" : "0px"}} className="center">
                    <p className="center top24 xxxlarge"><Translate>ВПІЗНАЛИ СЕБЕ?</Translate></p>
                    <img alt="" src={"/bkg/decor/seps" + (theme + 1) + ".png"} width="384px" className="bot24"/>
                </div>
            </div>
            {lines && lines.map((line, i) => {
                return <div key={i} style={{display: "flex", alignItems: "flex-start"}}>
                    <img alt="" src={"/bkg/decor/bullets" + (theme + 1) + ".png"} width="48px" className="bot16"/>
                    <div style={{textAlign: "left", marginLeft: "18px"}}
                        className="bot16">{line}</div>
                </div>
            })}
        </div>
    </div>
}

export default GeneralGoals