import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import { Navigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import SyncLoader from "react-spinners/SyncLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import UserActivity from "./UserActivity";
import Footer from "../Components/Footer";
import DocPreview from "../Components/DocPreview";
import MyProfileButton from "../Components/MyProfileButton";
import HomeButton from "../Components/HomeButton";
import CabHeader from "../Cabinet/CabHeader";
import MagBanner from "../Components/MagBanner";

function PublicProfileActivity() {
    const user = useParams()["id"];
    const [state, setState] = useState({items: [], hasMore: true, offset: 0});
    const [userName, setUserName] = useState(" ");
    const [preview, setPreview] = useState(null);
    const limit = 10;

    const onPreview = (data) => {
        setPreview(data);
    }

    const getActivitySuccess = (data) => {
        if (data && data.length > 0) {
            setState({
                items: state.items.concat(data),
                hasMore: data.length >= limit,
                offset: state.offset + data.length
            });
        } else getActivityError(data);
    }

    const getActivityError = (data) => {
        setState({items: state.items, hasMore: false, offset: state.offset});
    }

    const fetchMoreData = () => {
        getAPICall('user/activity', {user: user, offset: state.offset, limit: limit},
            getActivitySuccess, getActivityError);
    }

    useEffect(() => {
        const getNameSuccess = (data) => {
            if (data.length === 1) setUserName(data[0].name);
        }
        if (user && user >= 0) {
            getAPICall('user/name', {user: user}, getNameSuccess);
            fetchMoreData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return !user || user < 0 ? <Navigate to="/" replace={true}/> : <>
    {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} path={preview["path"]}/>}
    <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu />
        <MagBanner title="Історія" bkgColor="#fee6dd" icon="/icons/time.png"/>
        <div style={{height: "24px"}}/>
        <CabHeader user={user} userName={userName}/>
        <div className="userProfileContent">
            <div id="notifScrollableDiv" style={{display: "flex", justifyContent: "center"}}>
                <InfiniteScroll style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                    dataLength={state.items.length}
                    next={fetchMoreData}
                    hasMore={state.hasMore}
                    loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                    scrollableTarget="notifScrollableDiv"
                    endMessage={<></>}>
                    {state.items.map((data, index) => 
                        <UserActivity key={index} user={user} userName={userName} data={data} onPreview={onPreview}/>)}
                </InfiniteScroll>
            </div>
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
            backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div>
    </>
}

export default PublicProfileActivity