import { useEffect, useRef, useState } from "react";

const HorizontalScroll = ({ children, itemSize = 200, initPos = 0, color="" }) => {
    const scrollContainerRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const [fit, setFit] = useState(false);

    useEffect(() => {
      const retryInitialCheck = (trials) => {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        if (scrollWidth === 0) {
          if (trials < 10) {
            setTimeout(() => {
              retryInitialCheck(trials + 1);
            }, 100);
          } else {
            setIsAtStart(true);
            setIsAtEnd(false);  
          }
        } else {
          const fit = clientWidth + 10 >= scrollWidth;
          setIsAtStart(scrollLeft === 0);
          setIsAtEnd(fit);
          setFit(fit);
          if (initPos > 0) scrollContainerRef.current.scrollLeft = initPos;
        }
      }
      if (scrollContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        if (scrollWidth === 0) {
          setTimeout(() => {
            retryInitialCheck(0);
          }, 100);  
        } else {
          const fit = clientWidth + 10 >= scrollWidth;
          setIsAtStart(scrollLeft === 0);
          setIsAtEnd(fit);
          setFit(fit);
          if (initPos > 0) scrollContainerRef.current.scrollLeft = initPos;
        }
      }
    }, [children, itemSize, initPos]);

    const checkScrollPosition = () => {
        if (scrollContainerRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
          setIsAtStart(scrollLeft === 0);
          setIsAtEnd(scrollLeft + clientWidth + 10 >= scrollWidth);
        }
    };

    const scrollLeft = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          left: -itemSize,
          behavior: 'smooth'
        });
        setTimeout(checkScrollPosition, 300);
      }
    };
  
    const scrollRight = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          left: itemSize,
          behavior: 'smooth'
        });
        setTimeout(checkScrollPosition, 300);
      }
    };
  
    const handleMouseDown = (e) => {
      scrollContainerRef.current.isDown = true;
      scrollContainerRef.current.startX = e.pageX - scrollContainerRef.current.offsetLeft;
    };
  
    const handleMouseLeave = () => {
      scrollContainerRef.current.isDown = false;
    };
  
    const handleMouseUp = () => {
      scrollContainerRef.current.isDown = false;
    };
  
    const handleMouseMove = (e) => {
      if (!scrollContainerRef.current.isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - scrollContainerRef.current.startX) * 2; // Scroll-fast
      scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft - walk;
      setTimeout(checkScrollPosition, 300);
    };
  
    return (
      <div className="horizontal-scroll">
        <button className={`scroll-button left${color} ${isAtStart ? 'disabled' : ''}`} onClick={scrollLeft}/>
        <div style={fit ? {width: "100%", justifyContent: "center"} : {}}
          className="scroll-container preventSelect"
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {children}
        </div>
        <button className={`scroll-button right${color} ${isAtEnd ? 'disabled' : ''}`} onClick={scrollRight}/>
      </div>
    );
  };
  
  export default HorizontalScroll