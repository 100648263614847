import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext/AppContext";
import Translate, { translate } from "../../AppContext/Translate";
import NumericSpinner from "../../Components/NumericSpinner";
import Dropdown from 'react-bootstrap/Dropdown';
import { getAPICall } from "../../Components/APICall";
import Form from 'react-bootstrap/Form';
import Menu from "../../Menu/Menu";
import Footer from "../../Components/Footer";
import CabHomeButton from "../CabHomeButton";
import CabTitle from "../CabTitle";
import DirectorReport from "./DirectorReport";
import TeacherReport from "./TeacherReport";
import AdminReport from "./AdminReport";
import { useParams } from "react-router-dom";
import MagBanner from "../../Components/MagBanner";

function CabReport() {
    const initialRole = useParams()["id"];
    const { dicLang, userId, token } = useContext(AppContext);
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());
    const [role, setRole] = useState(initialRole && ['teacher', 'director', 'admin'].includes(initialRole) ?
        initialRole : 'teacher');
    const [admin, setAdmin] = useState(false);
    const [mySchool, setMySchool] = useState(null);
    
    useEffect(() => {
        getAPICall('cabinet/myschool', {userId: userId, token: encodeURIComponent(token)}, (data) => {
                if (data && data.length > 0) {
                    const row = data[0];
                    setMySchool({school: row.id, title: row.title});
                }
            });
        getAPICall('cabinet/admin', {userId: userId, token: encodeURIComponent(token)},
            (data) => {
                if (data && 'admin' in data) {
                    setAdmin(data.admin === 1);
                }
            });
    }, [userId, token]);

    const months = translate("Січень Лютий Березень Квітень Травень Червень Липень Серпень Вересень Жовтень Листопад Грудень", dicLang).split(' ');

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Фінанси" icon="/icons/report.png"/>
        <div style={{padding: "10px", backgroundColor: "white"}}>
            <CabTitle/>
            <div style={{display: "flex", alignItems: "center"}}>
                <Dropdown>
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" style={{minWidth: "200px", marginRight: "10px"}}>
                        {months[month]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {months.map((m, i) => { return <Dropdown.Item key={i} onClick={() => setMonth(i)}>{m}</Dropdown.Item>})}
                    </Dropdown.Menu>
                </Dropdown>
                <NumericSpinner amount={year} onChange={(y) => setYear(y)} max={9999} width={64} />
            </div>
            <div style={{border: "solid thin", borderRadius: "12px", marginTop: "8px", maxWidth: "330px",
                padding: "6px", marginBottom: "18px"}}>
                <p style={{marginBottom: "4px", fontWeight: "600"}}><Translate>Роль</Translate></p>
                <Form.Check defaultChecked={role === 'teacher'} type='radio' id='radio-teacher' label={translate('Викладач', dicLang)} 
                    name="rolGroup" onChange={() => { setRole('teacher'); }}/>
                {mySchool && <Form.Check defaultChecked={role === 'director'} type='radio' id='radio-director' label={translate('Директор школи', dicLang)} 
                    name="rolGroup" onChange={() => { setRole('director'); }}/>}
                {admin && <Form.Check defaultChecked={role === 'admin'} type='radio' id='radio-admin' label={translate('Адміністратор', dicLang)} 
                    name="rolGroup" onChange={() => { setRole('admin'); }}/>}
            </div>
            {role === 'teacher' && <TeacherReport month={month} year={year} />}
            {role === 'director' && mySchool && <DirectorReport school={mySchool} month={month} year={year} />}
            {role === 'admin' && admin && <AdminReport month={month} year={year} />}
            <CabHomeButton/>
        </div>
        <Footer/>
    </div>
}

export default CabReport