import BeatLoader from "react-spinners/BeatLoader"
import Translate, { formatDate } from "../../AppContext/Translate"
import UserPhotoIcon from "../../Components/UserPhotoIcon"

function UserList({users, setSelected}) {
    return <>
        <p className="xlarge fkenyan">
            <img alt="" src="/icons/contact.png" height="22px" style={{margin: "-4px 6px 0px 0px"}}/>
            <Translate>Чати</Translate>
        </p>
        <div style={{borderTopStyle: "solid", paddingTop: "16px"}}>
            {!users ? <BeatLoader/> : users.map((user, i) => {
                return <div key={i} className="msg-contact" onClick={() => {
                    setSelected({user: user.user, name: user.userName});
                }}>
                    <div style={{display: "flex"}}>
                        <UserPhotoIcon user={user.user} userName={user.userName}/>
                        <div style={{marginLeft: "8px"}}>
                            <p>{user.userName}</p>
                            <p className="small italic">{formatDate(user.lastMsgDate)}</p>
                        </div>
                    </div>
                    {user.unreadMessages > 0 && <p className="small" style={{backgroundColor: "#12c191",
                        padding: "3px 7px 2px 6px", borderRadius: "50%"}}>{user.unreadMessages}</p>}
                </div>
            })}
            {users && users.length === 0 && <p><Translate>У вас немає повідомлень</Translate>.</p>}
        </div>
    </>
}

export default UserList