import { useEffect, useState } from "react";
import { getAPICall } from "../../Components/APICall";
import Translate from "../../AppContext/Translate";
import { getAltBackgroundColor, getSoftBackgroundColor } from "../Themes/ThemeColors";
import GeneralTeacher from "./GeneralTeacher";

function GeneralTeam({course, routeChange, theme}) {
    const [teachers, setTeachers] = useState(null);

    useEffect(() => {
        getAPICall('course/teachers', {courseId: course}, 
            (data) => { setTeachers(data); });
    }, [course]);

    return teachers && teachers.length > 0 ?
        <div className="pad12">
            <div style={{backgroundColor: getAltBackgroundColor(theme), color: "white", padding: "4px 16px",
                borderRadius: "16px", display: "inline-block", zIndex: 100}}>
                <p className="xxxlarge"><Translate>Команда курсу</Translate>:</p>
            </div>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around",
                backgroundColor: getSoftBackgroundColor(theme), borderRadius: "16px", marginTop: "-24px",
                padding: "40px 16px 16px 16px"}}>
                {teachers.map((item, i) => {
                    return <GeneralTeacher key={i} teacher={item} routeChange={routeChange} theme={theme}/>
                })}
            </div>
        </div>
    : <></>
}

export default GeneralTeam