import Table from 'react-bootstrap/Table';
import Translate from '../AppContext/Translate';

export function calculateTotalOrder(subtotal, promo) {
    var discount = 0;
    if (promo) {
        if (promo.kind === 0) discount = promo.discount;
        else discount = Math.round(subtotal * promo.discount) / 100; 
    }
    var total = subtotal - discount;
    if (total < 0) total = 0;
    return {total: total, discount: discount};
}

function CartTotals({subtotal, promo}) {
    var calc = calculateTotalOrder(subtotal, promo);

    return <Table striped style={{fontSize: "large"}}>
        <tbody>
            <tr>
                <td><Translate>Сума</Translate></td>
                <td style={{textAlign: "right"}}>{subtotal.toFixed(2)} грн</td>
            </tr>
            { calc.discount > 0 &&
            <tr>
                <td><Translate>Знижка</Translate></td>
                <td style={{textAlign: "right"}}>{calc.discount.toFixed(2)} грн</td>
            </tr>
            }
            <tr style={{fontWeight: "600"}}>
                <td><Translate>Всього</Translate></td>
                <td style={{textAlign: "right"}}>{calc.total.toFixed(2)} грн</td>
            </tr>
        </tbody>
    </Table>
}

export default CartTotals