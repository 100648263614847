import { useContext, useState, useEffect } from "react";
import Menu from "../Menu/Menu"
import Footer from "../Components/Footer"
import UserCover from "./UserCover"
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Translate, { translate } from "../AppContext/Translate";
import { AppContext } from "../AppContext/AppContext";
import Autocomplete from "react-google-autocomplete";
import { getAPICall, postAPICall } from "../Components/APICall";
import Switch from 'react-switch';
import Button from "react-bootstrap/Button";
import WriteHelper from "../Components/WriteHelper";
import validator from 'validator'
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import HomeButton from "../Components/HomeButton";
import CabUploadLocalFile from "../Cabinet/CabUploadLocalPNG";
import RTEditor from "../Components/RTEditor";
import { useNavigate } from "react-router-dom";

function UserProfile() {
    const { userId, token, dicLang } = useContext(AppContext);
    const [name, setName] = useState('');
    const [profession, setProffession] = useState({text: '', files: []});
    const [diplomaName, setDiplomaName] = useState('');
    const [description, setDescription] = useState({text: '', files: []});
    const [city, setCity] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [webpage, setWebpage] = useState('');
    const [msgs, setMsgs] = useState(true);
    const [openTasks, setOpenTasks] = useState(true);
    const [saveInfo, setSaveInfo] = useState(null);
    const [canSignDiploma, setCanSignDiploma] = useState(false);
    const navigate = useNavigate(); 

    useEffect(() => {
        const profileSuccess = async (data) => {
            if (data && data.length === 1) {
                const row = data[0];
                setName(row.name ? row.name : '');
                const parsedProf = await WriteHelper.parseMessage(row.profession ? row.profession : '', null);
                setProffession(parsedProf);
                const parsedDesc = await WriteHelper.parseMessage(row.description ? row.description : '', null);
                setDescription(parsedDesc);                
                setCity(row.city ? row.city : '');
                setFacebook(row.facebook ? row.facebook : '');
                setInstagram(row.instagram ? row.instagram : '');
                setTwitter(row.twitter ? row.twitter : '');
                setWebpage(row.webpage ? row.webpage : '');
                setDiplomaName(row.diplomaName ? row.diplomaName : '');
            }
        }
        const prefSuccess = (data) => {
            if (data && data.length === 1) {
                const row = data[0];
                if (row.permitirMsgs && row.permitirMsgs !== 0) setMsgs(true);
                else setMsgs(false);
                if (row.openTasks && row.openTasks !== 0) setOpenTasks(true);
                else setOpenTasks(false);
            }
        }
        if (userId >= 0) {
            getAPICall('user/profile', {user: userId}, profileSuccess);
            getAPICall('user/preferences', {userId: userId, token: encodeURIComponent(token)}, prefSuccess);
            getAPICall('user/canSignDiploma', {userId: userId, token: encodeURIComponent(token)}, (data) => {
                if (data && 'can' in data) setCanSignDiploma(data.can === 1);
            });
        }
    }, [userId, token]);

    const saveSuccess = (data) => {
        if (data && 'error' in data && data.error === 'none') {
            setSaveInfo({text: <Translate>Зміни успішно збережено</Translate>, 
                color: "#deebfe"});
            navigate("/profile/" + userId);
        } else saveError();
    }

    const saveError = () => {
        setSaveInfo({text: <><Translate>Помилка</Translate>: <Translate>не вдалося зберегти зміни</Translate></>, 
                color: "mistyrose"});
    }

    const saveChanges = () => {
        if (facebook !== '' && !validator.isURL(facebook)) {
            setSaveInfo({text: <><Translate>Сторінка Facebook</Translate>: <Translate>адреса недійсна</Translate></>, 
                color: "mistyrose"});
        } else if (instagram !== '' && !validator.isURL(instagram)) {
            setSaveInfo({text: <><Translate>Сторінка Instagram</Translate>: <Translate>адреса недійсна</Translate></>, 
                color: "mistyrose"});
        } else if (twitter !== '' && !validator.isURL(twitter)) {
            setSaveInfo({text: <><Translate>Сторінка Twitter</Translate>: <Translate>адреса недійсна</Translate></>, 
                color: "mistyrose"});
        } else if (webpage !== '' && !validator.isURL(webpage)) {
            setSaveInfo({text: <><Translate>Персональний сайт</Translate>: <Translate>адреса недійсна</Translate></>, 
                color: "mistyrose"});
        } else {
            postAPICall('user/profile', {userId: userId, token: token, name: name,
                profession: WriteHelper.encode(WriteHelper.filterSimpleText(profession.text)),
                description: WriteHelper.encode(WriteHelper.filterSimpleText(description.text)),
                city: city, facebook: facebook, instagram: instagram,
                twitter: twitter, webpage: webpage, msgs: msgs, openTasks: openTasks,
                diplomaName: diplomaName}, saveSuccess, saveError);
        }
    }

    const changes = () => {
        setSaveInfo({text: <Translate>Є зміни, які очікують на збереження</Translate>, 
            color: "aliceblue"});
    }

    return userId >= 0 ?
    <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu />
        <div style={{backgroundColor: "#fef5de"}}>
            <div style={{width: "100%", maxWidth: "1024px", margin: "auto"}}>
                <UserCover user={userId} allowChange={true}/>
            </div>
        </div>
        <Container>
            <div className="userProfileForm" style={{marginTop: "min(84px, 15vw)"}}>
                <h2><Translate>Про вас</Translate></h2>
                <Form>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label style={{fontWeight: "600"}}><Translate>Ім'я</Translate></Form.Label>
                        <Form.Control value={name} type="text" maxLength="60" 
                            placeholder={translate("Напишіть своє ім'я", dicLang)}
                            onChange={e => { setName(WriteHelper.filterSimpleText(e.target.value)); changes(); }} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formDesc">
                        <Form.Label style={{fontWeight: "600"}}><Translate>Розкажіть щось про себе</Translate></Form.Label>
                        <RTEditor item={description} onChange={(data) => {
                            if (data.text.length < 4000) {
                                setDescription(data);
                                changes();
                            }
                        }} allowFiles={false} allowImages={false} allowVideos={false}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formProfession">
                        <Form.Label style={{fontWeight: "600"}}><Translate>Професійний профіль</Translate></Form.Label>
                        <RTEditor item={profession} onChange={(data) => {
                            if (data.text.length < 4000) {
                                setProffession(data);
                                changes();
                            }
                        }} allowFiles={false} allowImages={false} allowVideos={false}/>
                    </Form.Group>
                    {canSignDiploma && <>
                        <CabUploadLocalFile path={'/images/signature' + userId + '.png'}
                            defaultImage='/images/nosignature.png' uploadApi='cabinet/uploadSignature'
                            params={{userId: userId, token: token}} updatable={false} title='Підпис для диплома'
                            description='Підпис повинен бути зроблений синьою ручкою на прозорому тлі. Формат має бути PNG із розміром 680x240 пікселів'
                            dimensions={{width: 680, height: 240}}/>
                        <Form.Group className="mb-3" controlId="formDiplomaname">
                            <Form.Label style={{fontWeight: "600"}}><Translate>Ім'я та прізвище для підписання дипломів</Translate></Form.Label>
                            <Form.Control value={diplomaName} type="text" maxLength="48"
                                onChange={e => { setDiplomaName(WriteHelper.filterSimpleText(e.target.value)); changes(); }} />
                        </Form.Group>
                    </>}
                    <Form.Group className="mb-3" controlId="formCity" style={{display: "flex", flexDirection: "column"}}>
                        <Form.Label style={{fontWeight: "600"}}><Translate>Місто</Translate></Form.Label>
                        <Autocomplete className="form-control" defaultValue={city}
                            apiKey={"AIzaSyAeymMoP373RK5SJvSFP4fnWWcRxojyP2M"}
                            onPlaceSelected={(place) => { setCity(WriteHelper.filterSimpleText(place.formatted_address)); changes(); }}/>
                    </Form.Group>
                    <div className="userProfileContact">
                        <p style={{fontWeight: "600"}}>
                            <span style={{backgroundColor: "white"}}><Translate>Контакти</Translate></span>
                        </p>
                        <Form.Group className="mb-3" controlId="formFacebook">
                            <Form.Label><Translate>Сторінка Facebook</Translate></Form.Label>
                            <Form.Control value={facebook} type="text" maxLength="100"
                                placeholder="https://www.facebook.com/..."
                                onChange={e => { setFacebook(WriteHelper.filterSimpleText(e.target.value)); changes(); }}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formInstagram">
                            <Form.Label><Translate>Сторінка Instagram</Translate></Form.Label>
                            <Form.Control value={instagram} type="text" maxLength="100"
                                placeholder="https://www.instagram.com/..."
                                onChange={e => { setInstagram(WriteHelper.filterSimpleText(e.target.value)); changes(); }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formTwitter">
                            <Form.Label><Translate>Сторінка Twitter</Translate></Form.Label>
                            <Form.Control value={twitter} type="text" maxLength="100"
                                placeholder="https://twitter.com/..."
                                onChange={e => { setTwitter(WriteHelper.filterSimpleText(e.target.value)); changes(); }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formWeb">
                            <Form.Label><Translate>Персональний сайт</Translate></Form.Label>
                            <Form.Control value={webpage} type="text" maxLength="100" placeholder="http..."
                                onChange={e => { setWebpage(WriteHelper.filterSimpleText(e.target.value)); changes(); }} />
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-3" controlId="formMsgs" style={{display: "flex", marginTop: "22px"}}>
                        <Form.Label><Translate>Дозволити особисті повідомлення від інших студентів</Translate></Form.Label>                            
                        <div style={{transform: "translate(10px, 4px)"}}>
                            <Switch checked={msgs} onChange={() => { setMsgs(!msgs); changes(); }}
                            onColor="#86d3ff" onHandleColor="#2693e6" handleDiameter={22}
                            uncheckedIcon={false} checkedIcon={false} disabled={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14} width={36} 
                            className="react-switch" id="material-switch"/>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formTasks" style={{display: "flex"}}>
                        <Form.Label><Translate>Дозволити моїм одногрупникам бачити мої завдання</Translate></Form.Label>                            
                        <div style={{transform: "translate(10px, 4px)"}}>
                            <Switch checked={openTasks} onChange={() => { setOpenTasks(!openTasks); changes(); }}
                            onColor="#86d3ff" onHandleColor="#2693e6" handleDiameter={22}
                            uncheckedIcon={false} checkedIcon={false} disabled={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={14} width={36} 
                            className="react-switch" id="material-switch"/>
                        </div>
                    </Form.Group>
                    <div style={{textAlign: "right"}}>
                        <Button onClick={saveChanges}><Translate>Зберегти зміни</Translate></Button>
                    </div>
                    { saveInfo &&
                      <div style={{textAlign: "center", fontStyle: "italic", marginTop: "8px",
                        backgroundColor: saveInfo.color}}>{saveInfo.text}</div>
                    }
                </Form>
            </div>
            <div style={{margin: "16px 0px 16px 0px", textAlign: "center"}}>
                <Link to={"/profile/" + userId}>
                    <Button variant="outline-secondary"><Translate>Як інші користувачі бачать мій профіль?</Translate></Button>
                </Link>
            </div>
        </Container>
        <HomeButton/>
        <div style={{height: "24px"}}/>
        <Footer/>
    </div> :
    <Navigate to="/" replace={true} />
}

export default UserProfile