import Translate from "../../AppContext/Translate"
import { getMatterColor } from "../Themes/ThemeColors"

function MatterBox({index, title, theme}) {
    return <div style={{width: "260px", backgroundColor: getMatterColor(theme, index), margin: "8px",
        borderBottomLeftRadius: "16px", borderBottomRightRadius: "16px", color: "white"}}>
        <img alt="" src={"/bkg/gc" + index + ".jpg"} width="100%"/>
        <div style={{width: "100%", height: "84px", display: "flex", alignItems: "center"}} className="bold large pad16">
            {title}
        </div>
    </div>
}

function GeneralMatter({numUnits, numVideoMaterials, numTasks, theme}) {
    return <div className="pad24">
        <div style={{margin: "auto", width: "fit-content"}}>
            <p className="top24 xxxlarge" style={{color: "#664678", margin: "8px"}}><Translate>З ЧОГО СКЛАДАЄТЬСЯ КУРС?</Translate></p>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                {numUnits > 0 && <MatterBox index={1} theme={theme} title={<p>{numUnits} <Translate>модулів</Translate></p>}/>}
                {numVideoMaterials > 0 && <MatterBox index={2} theme={theme} title={<p>{numVideoMaterials} <Translate>відео уроків</Translate></p>}/>}
                <MatterBox index={3} theme={theme} title={<p><Translate>додаткові матеріали</Translate>{numTasks > 0 && <><br/><Translate>домашні завдання</Translate></>}</p>}/>
            </div>
        </div>
    </div>
}

export default GeneralMatter