import { getAltBackgroundImageColor } from "../Themes/ThemeColors"

function GeneralReq({width, theme, lines}) {
    return <div style={{width: width >= 720 ? "50%" : "100%", color: "white"}}>
        <div style={{backgroundColor: getAltBackgroundImageColor(theme), margin: "32px", borderRadius: "6px",
            padding: "8px 24px"}}>
            <ol>
                {lines.map((line, i) => {
                    return <li key={i} style={{ display: "flex", alignItems: "center" }}>
                        <div style={{marginLeft: "8px"}}>{line}</div>
                    </li>
                })}
            </ol>
        </div>
    </div>
}

export default GeneralReq