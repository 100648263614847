import Card from 'react-bootstrap/Card';
import Translate from '../AppContext/Translate';
import { Link } from 'react-router-dom';

function CabinetCard({icon, title, desc, school=-1}) {
    return <Link to={school < 0 ? '/cabinet/' + icon : '/school/' + school}> 
        <Card style={{ width: '18rem', border: "none", padding: "12px"}}>
            <Card.Img variant="top" src={"/icons/" + icon + ".png"} alt={icon}
                style={{width: "148px", border: "solid black", margin: "auto", padding: "16px", borderRadius: "16px"}} />
            <Card.Body style={{paddingTop: "8px"}}>
                <Card.Title className='fkenyan'><Translate>{title}</Translate></Card.Title>
                <Card.Text style={{borderTop: "solid thin #61583e", color: "#22afdf"}}>
                    <Translate>{desc}</Translate>.
                </Card.Text>
            </Card.Body>
        </Card>
    </Link>
}

export default CabinetCard