import Footer from "../../Components/Footer"
import HomeButton from "../../Components/HomeButton"
import MagBanner from "../../Components/MagBanner"
import Menu from "../../Menu/Menu"
import ShowProjects from "./ShowProjects"

function Projects() {
    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Проєкти" bkgColor="#fef5de" 
            decor={<img alt="" src="/bkg/banners/project.png" height="100%"
                style={{position: "absolute", right: "20px", top: "-30%"}}/>}/>
        <ShowProjects courseId={0}/>
        <HomeButton/>
        <div style={{height: "24px"}}/>
        <Footer/>
    </div>
}

export default Projects