import UserRank from "../UserRank"
import AboutMe from "./AboutMe"
import CabinetButton from "./CabinetButton"
import City from "./City"
import Contact from "./Contact"
import EditButton from "./EditButton"
import FollowButton from "./FollowButton"
import MessageButton from "./MessageButton"
import Profession from "./Profession"
import SendMessage from "./SendMessage"
import StudentIcon from "./StudentIcon"
import StudentMenu from "./StudentMenu"
import TeacherIcon from "./TeacherIcon"
import TeacherMenu from "./TeacherMenu"

function ProfileBig({user, profile, own, isTeacher, isStudent, unreadMessages, followRef, userId, token}) {
    return <>
        <div style={{display: "flex", alignItems: "flex-end", justifyContent: "space-between"}}>
            <div style={{marginTop: "min(92px, 14vw)"}} className="pad12">
                {profile.name && <h1 style={{margin: "0px"}}>{profile.name}</h1>}
                <City city={profile.city}/>
            </div>
            <div style={{display: "flex", marginBottom: "12px"}}>
                <div style={{backgroundColor: "white", borderRadius: "4px", display: "flex",
                    justifyContent: "space-around", alignItems: "center", minWidth: "240px"}}>
                    <div style={{padding: "6px"}}>
                        {isStudent && <StudentIcon/>}
                        {isTeacher && isStudent && <div style={{height: "6px"}}/>}
                        {isTeacher && <TeacherIcon/>}
                    </div>
                    <div style={{padding: "6px"}}>
                        <UserRank user={user}/>
                    </div>
                </div>
                <div style={{backgroundColor: "white", borderRadius: "4px", marginLeft: "6px", minWidth: "100px"}}>
                    {own ? <div style={{display: "flex", justifyContent: "space-evenly", height: "100%", alignItems: "center"}}>
                        <MessageButton unreadMessages={unreadMessages}/>
                        <FollowButton followRef={followRef} own={own} user={user} userId={userId} token={token}/>
                    </div> : <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center",
                        padding: "0px 8px"}}>
                        <FollowButton followRef={followRef} own={own} user={user} userId={userId} token={token}/>
                    </div>}
                </div>
            </div>
            <div className="pad12">
                {own ? <div className="center">
                    <EditButton/>
                    <div style={{height: "8px"}}/>
                    <CabinetButton/>
                </div> : <img alt="" src="/bkg/decor/dec13.png" width="80%" style={{maxWidth: "54px",
                    transform: "translateY(-48px)"}}/>}
            </div>
        </div>
        <div style={{display: "flex", alignItems: "flex-start"}}>
            <div style={{backgroundColor: "white", margin: "0px 12px", borderRadius: "4px", 
                width: isStudent || isTeacher ? "60%" : "100%"}}
                className="pad24">
                <AboutMe text={profile.description}/>
                <Profession text={profile.profession}/>
                <Contact profile={profile}/>
                <img alt="" src="/bkg/decor/dec14.png" style={{position: "absolute", left: "0px", width: "50%",
                    maxWidth: "200px", zIndex: 100, transform: "translate(-100%, 40px)"}}/>
            </div>
            {(isStudent || isTeacher) && 
                <div style={{backgroundColor: "white", borderRadius: "4px",
                    display: "flex", width: "40%"}} className="pad12">
                {isStudent && <StudentMenu own={own} userId={user} width={isTeacher ? "50%" : "100%"}/>}
                {isTeacher && <TeacherMenu own={own} userId={user} isStudent={isStudent} width={isStudent ? "50%" : "100%"}/>}
            </div>}
        </div>
        {!own && profile.permitirMsgs === 1 && userId >= 0 ?
            <SendMessage toUser={user} fromUser={userId} token={token}/> :
            <div style={{height: "84px"}}/>}
    </>
}

export default ProfileBig