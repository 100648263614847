import { useState, useEffect, useContext } from "react";
import { getAPICall } from "../../Components/APICall";
import EditProduct from "./EditProduct";
import CabTitle from "../CabTitle";
import { AppContext } from "../../AppContext/AppContext";
import { Navigate } from "react-router-dom";
import Menu from "../../Menu/Menu";
import Translate from "../../AppContext/Translate";
import CabHomeButton from "../CabHomeButton";
import Footer from "../../Components/Footer";
import MagBanner from "../../Components/MagBanner";

function CabShop() {
    const { userId, token } = useContext(AppContext);
    const [school, setSchool] = useState(null);
    const [itemsFromSchool, setItemsFromSchool] = useState([]);
    const [itemsFromUser, setItemsFromUser] = useState([]);

    useEffect(() => {
        const getSchoolOk = (data) => {
            if (data && data.length > 0 && data[0].approvalDate !== null) {
                setSchool(data[0].id);
            }
        }
        getAPICall('cabinet/myschool', {userId: userId, token: encodeURIComponent(token)}, getSchoolOk);
        getAPICall('user/items', {userId: userId, allData: 0}, (data) => { if (data) setItemsFromUser(data); });
    }, [userId, token]);

    const forceUpdate = (schoolId) => {
        getAPICall('shop/listItems', {school: schoolId}, (i) => {
            if (i) {
                var aux = [];
                for (var j = i.length - 1; j >= 0; j--) {
                    aux.push(i[j].id);
                }
                setItemsFromSchool(aux);
            }
        });
    }

    useEffect(() => {
        if (school) forceUpdate(school);
    }, [school]);

    return userId < 0 ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Магазин" icon="/icons/shop.png"/>
        <div className="pad12">
            <CabTitle/>
            {school && <>
                <p className="bold top4 bot16 large"><Translate>Товари з вашої школи</Translate></p>
                <EditProduct school={school} itemId={-1} forceUpdate={() => { forceUpdate(school); }}/>
                <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "center"}}>
                    {itemsFromSchool.map(item => {
                        return <EditProduct key={item} school={school} itemId={item}
                            forceUpdate={() => { forceUpdate(school); }}/>
                    })
                    }
                </div>
            </>}
            {itemsFromUser.length > 0 && <>
                <p className={"bold bot16 large" + (school ? " top36" : " top4")}><Translate>Продукти, які ви створили</Translate></p>
                <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "center"}}>
                    {itemsFromUser.map(item => {
                        return <EditProduct key={item.id} school={school} itemId={item.id} forceUpdate={null}/>
                    })
                    }
                </div>
            </>}
        </div>
        <CabHomeButton/>
        <Footer/>
    </div>
}

export default CabShop