import Translate from "../AppContext/Translate"
import HomeButton from "../Components/HomeButton"

function Decline() {
    return <div className="magSection" style={{backgroundColor: "#fef5de", maxWidth: "800px", margin: "48px auto",
        flexDirection: "column", alignItems: "center"}}>
        <img alt="" src="/logo192.png" width="84px" style={{marginTop: "-56px"}}/>
        <p className="top18 bold large"><img alt="" src="/icons/sad.png" width="48px" className="right18"/><Translate>Вам не вдалося сплатити?</Translate></p>
        <div style={{height: "1.5px", width: "75%", backgroundColor: "black"}} className="top18"/>
        <p className="top18 large italic"><Translate>Чи безпечно сплачувати, вводячи данні своєї картки?</Translate></p>
        <p className="top8"><Translate>Ми підключили сервіс WayForPay для вашої зручності. Це повністю безпечно, адже данні вашої картки не отримує сервіс і, тим паче, ми. Вас лише переводить на строінку банк-клієнта для підтвердження платежу.</Translate></p>
        <div style={{height: "1.5px", width: "75%", backgroundColor: "black"}} className="top18"/>
        <p className="top18 bot24"><Translate>Якщо у вас виникли проблеми з оплатою та потрібна допомога, напишіть нам на</Translate> <a href="mailto:support@magistrika.com">support@magistrika.com</a></p>
        <HomeButton/>
    </div>
}

export default Decline