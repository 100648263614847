import Navbar from 'react-bootstrap/Navbar';
import { useContext } from "react";
import { AppContext } from "../AppContext/AppContext";
import { translate } from "../AppContext/Translate";
import SearchBar from '../Components/SearchBar';

function MenuSearch(props) {
    const { dicLang } = useContext(AppContext);
    const searchCourses = translate("Назва курсу або особи", dicLang) + "...";
    
    return (
        <Navbar.Text>
            <SearchBar placeholder={searchCourses} size={props.size} />
        </Navbar.Text>
    );
}

export default MenuSearch