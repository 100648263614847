import { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext/AppContext";
import { Navigate, useParams } from "react-router-dom";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import Translate from "../AppContext/Translate";
import CourseList from "../Lists/CourseList";
import { getAPICall } from "../Components/APICall";
import Order from "./Orders/Order";
import HomeButton from "../Components/HomeButton";
import MyProfileButton from "../Components/MyProfileButton";
import MagBanner from "../Components/MagBanner";
import CabHeader from "../Cabinet/CabHeader";

function UserOrders() {
    var expandedOrder = useParams()["id"];
    if (expandedOrder) {
        expandedOrder = parseInt(expandedOrder);
    }
    const { userId, userName, token } = useContext(AppContext);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getAPICall('orders/client', {userId: userId, token: encodeURIComponent(token)}, (data) => {
            if (data) setOrders(data);
        });
    }, [userId, token]);

    return userId >= 0 ? 
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Мої замовлення" bkgColor="#feb1d4" decor={<img alt="" src="/bkg/decor/dec19.png" 
            width="60%" style={{float: "right"}}/>}/>
        <div className="magSection" style={{backgroundColor: "#fef5de", flexDirection: "column"}}>
            <CabHeader user={userId} userName={userName}/>
            {orders.length === 0 ? <>
                <p style={{fontWeight: "600", fontStyle: "italic", marginTop: "16px"}}><Translate>Ви ще не зробили жодного замовлення</Translate>.</p>
                <p><Translate>Додайте курси або товари до кошика та розпочніть навчання з провідними фахівцями</Translate>.</p>
                <div style={{marginLeft: "-12px", marginRight: "-24px"}}>
                    <CourseList title="Курси, які можуть вас зацікавити"/>
                </div>
            </> : <div>
                {orders.map((data, i) => {
                    return <Order key={i} data={data} expandedOrder={expandedOrder}/>
                })}
                <div style={{height: "8px"}}/>
            </div>
            }
        </div>
        <div className="magSection" style={{justifyContent: "space-evenly", flexWrap: "wrap", backgroundColor: "#fef5de"}}>
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div> :
    <Navigate to="/" replace={true} />
}

export default UserOrders