import { Link } from "react-router-dom";
import Translate from "../AppContext/Translate"
import { forwardRef } from "react";

const MagButton = forwardRef(({text, icon=null, bkgColor="#f82424", color="white", translate=true, margin="8px",
        size="x-large", addStyle={}, iconWidth="24", clsName = null,
        clickEff={link: null, scrollRef: null, customFnc: null}}, ref) => {
    const handleClick = (event) => {
        if (clickEff.customFnc) {
            clickEff.customFnc();
        } else if (clickEff.scrollRef) {
            clickEff.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const button = (
        <div
          ref={ref}
          className={clsName ? "buttonShadow fkenyan " + clsName : "buttonShadow fkenyan"}
          style={{ ...addStyle, backgroundColor: bkgColor, color: color, margin: margin, fontSize: size }}
          onClick={clickEff ? handleClick : undefined}
        >
          {text && (translate ? <Translate>{text}</Translate> : text)}
          {icon && <img alt="" src={icon} width={iconWidth} style={{ marginLeft: text ? "8px" : "0px" }} />}
        </div>
      );
    
      return clickEff && clickEff.link ? (
        <Link to={clickEff.link}>
          {button}
        </Link>
      ) : (
        button
      );
});

export default MagButton