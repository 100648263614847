import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall, postAPICall } from "../../Components/APICall";
import Translate, { translate } from "../../AppContext/Translate";

function FollowButton({followRef, own, user, userId, token}) {
    const { dicLang } = useContext(AppContext);
    const [following, setFollowing] = useState(null);

    useEffect(() => {
        if (userId >= 0) {
            getAPICall('user/follows', {userId: userId, user: user, token: encodeURIComponent(token)}, (data) =>{
                if (data && 'follows' in data) setFollowing(data.follows === 1);
            });
        }
    }, [user, userId, token]);

    const saveProfileError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const saveProfile = (user, userId, token) => {
        postAPICall('user/follow', {userId: userId, user: user, token: token}, (data) => {
            if (data && 'error' in data && data.error === 'none') setFollowing(true);
            else saveProfileError();
        }, saveProfileError);
    }

    const removeProfile = (user, userId, token) => {
        postAPICall('user/delFollow', {userId: userId, user: user, token: token}, (data) => {
            if (data && 'error' in data && data.error === 'none') setFollowing(false);
            else saveProfileError();
        }, saveProfileError);
    }

    return own ? <div style={{padding: "4px"}}>
        <img alt="Зберегти профіль" src="/icons/follow.png" height="30px" style={{cursor: "pointer"}}
            onClick={() => { followRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }}/>
    </div> : (following ? <div style={{display: "flex", padding: "4px 8px", alignItems: "flex-start"}}>
        <div className="center">
            <img alt="Збережений профіль" src="/icons/follow.png" height="30px" style={{marginRight: "6px"}}/>
            <p><Translate>Збережений профіль</Translate></p>
        </div>
        <div style={{padding: "1px 3px 2px 3px", cursor: "pointer", textAlign: "center"}}
            onClick={() => { removeProfile(user, userId, token); }}>
            <img alt="Усунути" src="/icons/trash.png" height="20px"/>
        </div>
    </div> : userId >= 0 && <div style={{padding: "4px 8px", backgroundColor: "#12c191", color: "white", cursor: "pointer",
        textAlign: "center"}} onClick={() => { saveProfile(user, userId, token); }}>
        <img alt="Зберегти профіль" src="/icons/follow.png" height="30px" style={{marginRight: "6px", filter: "invert(1)"}}/>
        <Translate>Збережені профілі</Translate>
    </div>)
}

export default FollowButton