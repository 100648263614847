import Translate from "./Translate"
import { useState, useContext } from "react"
import { AppContext } from "./AppContext";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { getAPICall } from "../Components/APICall";

function Add2Cart({data, text, icon, student=null, buttonStyle={position: "relative", backgroundColor: "#ffad4d",
    padding: "14px", borderRadius: "8px", borderTopStyle: "solid", borderTopColor: "#bd8039", borderRightStyle: "solid",
    borderRightColor: "#bd8039", color: "white", fontWeight: "700", cursor: "pointer"}, content=null,
    classname = "buttonShadow fkenyan"}) {
    const { userId, addToCart, token } = useContext(AppContext);
    const [error, setError] = useState(false);
    const navigate = useNavigate(); 

    const doAdd2Cart2 = (data) => {
        if (addToCart(data)) {
            navigate("/cart");
        } else {
            setError({title: "Не вдалося додати в кошик",
                text: "У вашому кошику є несумісні товари",
                buttons: ['accept']});
        }
    }

    const doAdd2Cart = (data) => {
        if (data.kind === 'item') {
            doAdd2Cart2(data);
        } else {
            getAPICall('prevPkg/discount', {userId: userId, token: encodeURIComponent(token), pkgId: data.packageId},
                (d) => {
                    const discount = (d && 'discount' in d) ? d.discount : 0;
                    const addData = {...data, discount: discount};
                    doAdd2Cart2(addData);            
                }, () => {
                    setError({title: "Не вдалося додати в кошик",
                        text: "несподівана помилка",
                        buttons: ['accept']});
                });
        }
    }

    const clickToAddPlan = () => {
        var pkgBought = false;
        if (student) {
            for (var i = 0; i < student.length; i++) {
                if (student[i].package === data.packageId) {
                    pkgBought = true;
                    break;
                }
            }
        }
        if (pkgBought) {
            setError({title: "Не вдалося додати в кошик",
                text: "Ви вже придбали цей пакет. Ви можете отримати доступ до своїх курсів, натиснувши кнопку нижче",
                buttons: ['courses', 'cancel']});
        } else doAdd2Cart(data);
    }

    const clickToAddItem = () => {
        if (data.digital === 1) {
            getAPICall('user/purchasedItems', {userId: userId, token: encodeURIComponent(token)},
                (items) => {
                    var purchased = false;
                    if (items) {
                        for (var i = 0; i < items.length; i++) {
                            if (items[i].item === data.itemId) {
                                purchased = true;
                                break;
                            }
                        }
                    }
                    if (purchased) {
                        setError({title: "Не вдалося додати в кошик",
                            text: "Ви вже придбали цей товар",
                            buttons: ['product', 'cancel']});
                    } else doAdd2Cart(data);
                });
        } else doAdd2Cart(data);
    }

    const clickToAdd = () => {
        if (userId < 0) {
            setError({title: "Не вдалося додати в кошик",
                text: "Ви повинні створити обліковий запис, аби мати можливість робити покупки на платформі",
                buttons: ['account', 'cancel']});
        } else if (data.kind === "plan") clickToAddPlan();
        else clickToAddItem();
    }

    const handleClose = () => {
        setError(false);
    } 

    return <>
    <div style={buttonStyle} className={classname} onClick={() => { clickToAdd(); }}>
        {text ? (icon ? <>
            <span style={{marginRight: "48px"}}><Translate>{text}</Translate></span>
            <img style={{position: "absolute", width: "58px", top: "-1px", right: "-4px"}} alt="" src={icon}/>
         </> : <Translate>{text}</Translate>) 
         : content}
    </div>
    {error && <Modal show={error} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                <Translate>{error.title}</Translate>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Translate>{error.text}</Translate>
        </Modal.Body>
        <Modal.Footer>
            {error.buttons.includes('account') &&
            <Button variant="primary" onClick={() => {
                setError(false);
                const menuCreateAccountButton = document.getElementById('menuCreateAccountButton');
                if (menuCreateAccountButton) menuCreateAccountButton.click(); }}>
                <Translate>Створити акаунт</Translate>
            </Button>}
            {error.buttons.includes('courses') &&
            <Button variant="primary" onClick={() => { navigate("/user/courses"); }}>
                <Translate>Мої курси</Translate>
            </Button>}
            {error.buttons.includes('product') &&
            <Button variant="primary" onClick={() => { navigate("/product/" + data.itemId); }}>
                <Translate>Переглянути товар</Translate>
            </Button>}
            {error.buttons.includes('accept') &&
            <Button variant="primary" onClick={() => { setError(false); }}>
                <Translate>Прийняти</Translate>
            </Button>}
            {error.buttons.includes('cancel') &&
            <Button variant="secondary" onClick={() => { setError(false); }}>
                <Translate>Скасувати</Translate>
            </Button>}
        </Modal.Footer>
    </Modal>}
    </>
}

export default Add2Cart