import MagButton from "../Components/MagButton";
import UserPhotoIcon from "../Components/UserPhotoIcon"

function SchoolMember({id, name, role=null}) {
    return <div style={{display: "flex", alignItems: "flex-start"}}>
        <div style={{padding: "4px 4px 4px 8px", backgroundColor: "white", borderRadius: "50%"}}>
            <UserPhotoIcon user={id} userName={name} big={true}/>
        </div>
        <div style={{width: "100%", marginLeft: "8px"}}>
            <p className="bold large top8">{name}</p>
            {role && <p className="fkenyan top4">{role}</p>}
            <div style={{height: "4px"}}/>
            <MagButton text="Дізнатися більше" icon="/icons/info.png" bkgColor="white" color="black" size="small"
                iconWidth="16" clickEff={{link: "/load/profile/" + id}}/>
        </div>
    </div>
}

export default SchoolMember