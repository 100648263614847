import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Translate, { formatDate } from "../AppContext/Translate";
import { getAPICall } from "../Components/APICall";
import Button from 'react-bootstrap/Button';
import RateCourse from "./RateCourse";
import DiplomaButton from "./DiplomaButton";
import { FcDiploma1 } from 'react-icons/fc';

function UserPlan({course, ext, approvals}) {
    var diploma = {show: course.options && course.options.includes('Z'), available: false};
    if (diploma.show) {
        for (var i = 0; i < approvals.length; i++) {
            if (approvals[i].course === course.course) {
                diploma.available = true;
                break;
            }
        }
    }
    const [schoolInfo, setSchoolInfo] = useState(null);
    const [showRate, setShowRate] = useState(false);
    
    const courseLink = "/load/course/" + course.course;
    const pic = "url('/courses/cover" + course.course + ext + "')";
    const expirationDate = new Date(course.expirationDate);
    const today = new Date(course.today);
    
    useEffect(() => {
        getAPICall('courses/info', {courseId: course.course}, (data) => {
            if (data && data.length > 0) {
                const row = data[0];
                setSchoolInfo({id: row.schoolId, name: row.schoolName});
            }
        });
    }, [course.course, course.options]);

    return <div className="userPlanCard">
        <div style={{display: "flex"}}>
            <Link to={courseLink} style={{width: "40%"}}>
                <div style={{width: "100%", height: "100%", backgroundSize: "cover", backgroundImage: pic, backgroundPosition: "50% 50%"}}/>
            </Link>
            <div style={{padding: "8px", borderLeft: "thick double darkgray"}}>
                <Link to={courseLink}><p className="blackTextHover" style={{margin: "0px"}}>{course.courseTitle}</p></Link>
                <p style={{fontStyle: "italic"}}>{course.packageName}</p>
                {schoolInfo &&
                <p style={{color: 'darkcyan'}}>
                    <Translate>Курс від</Translate>
                    {' '}<Link className="schoolLink" to={"/school/" + schoolInfo.id}>{schoolInfo.name}</Link>
                </p>}
            </div>
        </div>
        <div style={{borderTop: "thin solid darkgray", marginTop: "2px"}}>
            {expirationDate.getFullYear() < 3000 &&
            <p style={{padding: "8px", fontSize: "smaller", margin: "0px"}}>{expirationDate <= today ? "Термін доступу до курсу завершується "
                : "Термін дії цього пакета завершився "}{formatDate(expirationDate)}</p>}
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", padding: "4px", alignItems: "center",
            marginBottom: "6px", marginTop: "4px"}}>
            <Link to={courseLink}><Button size="sm" variant="outline-dark"><Translate>Перейти до курсу</Translate></Button></Link>
            <Button size="sm" variant="outline-success" onClick={() => { setShowRate(true);}}><Translate>Оцініть цей курс</Translate></Button>
            <Link to={"/certificate/c" + course.id}>
                <Button size="sm" variant="outline-primary">
                    <FcDiploma1 color="green" size="22" style={{marginTop: "-2px", marginRight: "2px"}}/>
                    <Translate>Сертифікат</Translate></Button>
            </Link>
            {diploma.show && <DiplomaButton available={diploma.available} plan={course.id}/>}
        </div>
        <RateCourse title={course.courseTitle} courseId={course.course} show={showRate} setShow={setShowRate}/>
    </div>
}

export default UserPlan