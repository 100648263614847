import Translate from '../../AppContext/Translate';
import HorizontalScroll from '../../Components/HorizontalScroll';

function ForegroundImageSelector({selectedImage, setSelectedImage, category, colorTheme, setChanges}) {
    const imgs = Array.from({ length: 9 }, (_, index) => index + 1);
    const theme = colorTheme + 1;
    return <div style={{width: "100%"}}>
        <p className='top18'><Translate>Оберіть форму обкладинки</Translate>:</p>
        <HorizontalScroll itemSize={120} initPos={Math.max(0, selectedImage * 120 - 12)}>
            {imgs.map((i) => {
                return <div key={i} style={{width: "100px", minWidth: "100px", height: "100px", margin: "9px",
                    border: selectedImage + 1 === i ? "double 2px black" : "double 2px transparent",
                    cursor: "pointer", borderRadius: "6px", display: "flex", justifyContent: "center",
                    alignItems: "center"}} onClick={() => {
                        if (i !== selectedImage + 1) {
                            setSelectedImage(i - 1);
                            setChanges(true);
                        }
                    }}>
                    <img alt="" src={"/bkg/themes/c" + category + "_t" + theme + "_" + i + ".png"}
                        style={{width: "90px"}}/>
                </div>
            })}
        </HorizontalScroll>
    </div>
}

export default ForegroundImageSelector