import Menu from "../Menu/Menu"
import { RiEqualizerLine } from 'react-icons/ri';
import { useState, useEffect } from "react";
import ShopFilter from "./ShopFilter";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAPICall } from "../Components/APICall";
import Footer from "../Components/Footer";
import SyncLoader from "react-spinners/SyncLoader";
import Item from "./Item";
import HomeButton from "../Components/HomeButton";
import MagBanner from "../Components/MagBanner";

function Shop({showMenu=true, bkgColor="#e8e8e8", ignore=[]}) {
    const [showFilter, setShowFilter] = useState(false);
    const [school, setSchool] = useState(0);
    const [itemType, setItemType] = useState(0);
    const [state, setState] = useState({items: [], hasMore: true, offset: 0});
    const [ignoreIds,] = useState(new Set(ignore.map(objeto => objeto.id)));
    const limit = 48;

    const itemsSuccess = (data) => {
        if (data && data.length > 0) {
            var items = [...state.items];
            for (var i = 0; i < data.length; i++) {
              if (!ignoreIds.has(data[i].id))
                items.push(data[i]);
            }    
            setState({
                items: items,
                hasMore: data.length >= limit,
                offset: state.offset + data.length
            });
        } else itemsError(data);
    }

    const itemsError = (data) => {
        setState({items: state.items, hasMore: false, offset: state.offset});
    }

    const fetchMoreData = () => {
        getAPICall('shop/items', {lang: '', school: school, kind: itemType, offset: state.offset, limit: limit},
            itemsSuccess, itemsError);
    }

    useEffect(() => {
        fetchMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFilterSchool = (sc) => {
        setSchool(sc);
        state.items.splice(0, state.items.length);
        state.offset = 0;
        state.hasMore = true;
        getAPICall('shop/items', {lang: '', school: sc, kind: itemType, offset: state.offset, limit: limit},
            itemsSuccess, itemsError);
    }

    const onFilterType = (kind) => {
        setItemType(kind);
        state.items.splice(0, state.items.length);
        state.offset = 0;
        state.hasMore = true;
        getAPICall('shop/items', {lang: '', school: school, kind: kind, offset: state.offset, limit: limit},
            itemsSuccess, itemsError);
    }

    return <div className='mainContainer' style={{backgroundColor: bkgColor}}>
        {showMenu && <>
            <Menu/>
            <MagBanner title="Магазин" bkgColor="#a26159" titleColor="white" icon="/icons/shop.png"/>
            <div style={{padding: "48px 24px 8px 24px"}}>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <RiEqualizerLine className="userLink" size="24px" onClick={()=>{setShowFilter(!showFilter)}}/></div>
                <ShopFilter show={showFilter} setSchool={onFilterSchool} setItemType={onFilterType}/>
            </div>
        </> 
        }
        <div id="itemsScrollableDiv">
            <InfiniteScroll
                dataLength={state.items.length}
                next={fetchMoreData}
                hasMore={state.hasMore}
                loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                style={{display: "flex", flexWrap: "wrap"}}
                scrollableTarget="itemsScrollableDiv"
                endMessage={null}>
                {state.items.map((data, index) => 
                    <Item key={index} data={data}/>)}
            </InfiniteScroll>
        </div>
        <div style={{height: "16px"}}/>
        {showMenu && <>
            <HomeButton/>
            <div style={{height: "24px"}}/>
            <Footer/>
        </>}
    </div>
}

export default Shop