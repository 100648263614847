import { useContext, useEffect, useState } from "react";
import Footer from "../Components/Footer"
import MagBanner from "../Components/MagBanner"
import Menu from "../Menu/Menu"
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import BeatLoader from "react-spinners/BeatLoader";
import CategoryCard from "../Home/CategoryCard";
import HomeButton from "../Components/HomeButton";

function Categories() {
    const { lang } = useContext(AppContext);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getAPICall('categories/count', {lang: lang}, (data) => {
            if (data) setCategories(data);
        });
    }, [lang]);

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Категорії" bkgColor="#fac032"
            decor={<img alt="" src="/bkg/decor/dec5.png" width="60%" style={{float: "right"}}/>}/>
        <div className="magSection" style={{flexDirection: "column"}}>
            <p className="top24 bot24 bold xlarge"><Translate>обирай напрямок навчання</Translate></p>
            <div style={{display: "flex", flexWrap: "wrap"}} className="bot32">
                {categories.length === 0 ? <BeatLoader /> :
                categories.map((cat, i) => {
                    return <CategoryCard key={i} category={cat}/>
                })}
            </div>
            <HomeButton/>
        </div>
        <Footer/>
    </div>

}

export default Categories