import { useEffect, useRef, useState } from "react";
import CountUp from "../../Components/CountUp";
import { RxCross2 } from "react-icons/rx";
import { useWindowSize } from "../../AppContext/WindowSize";

function TableProgress({ textPairs }) {
    const containerRefs = useRef([]);
    const leftTextRefs = useRef([]);
    const rightTextRefs = useRef([]);
    const [lineWidths, setLineWidths] = useState([]);
    const [width,] = useWindowSize();

    useEffect(() => {
      const measureText = (text, font) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        return context.measureText(text).width;
      };
  
      const updateLineWidths = () => {
        const newLineWidths = containerRefs.current.map((containerRef, index) => {
          if (containerRef && leftTextRefs.current[index] && rightTextRefs.current[index]) {
            const containerWidth = containerRef.clientWidth;
            const leftTextStyle = window.getComputedStyle(leftTextRefs.current[index]);
            const rightTextStyle = window.getComputedStyle(rightTextRefs.current[index]);
            
            const leftTextWidth = measureText(textPairs[index].leftText, leftTextStyle.font);
            const rightTextWidth = measureText(textPairs[index].rightText, rightTextStyle.font);
            return Math.max(0, containerWidth - leftTextWidth - rightTextWidth - 40);
          }
          return 0;
        });
        setLineWidths(newLineWidths);
      };
  
      updateLineWidths();
    }, [textPairs, width]);
  
    const arrowy = width < 406 ? "-0.5" : (width < 450 ? "-2" : "-3");

    return (
      <div style={{ display: 'flex', flexDirection: 'column', fontSize: width < 406 ? "x-small" : (width < 450 ? "small" : "medium")}}>
        {textPairs.map((pair, index) => (
          <div key={index} ref={el => containerRefs.current[index] = el}
            style={{ display: 'flex', alignItems: 'center', width: '100%', margin: "2px 0px" }}>
            <span ref={el => leftTextRefs.current[index] = el} style={{ padding: '0 10px', whiteSpace: 'nowrap' }}>
                {pair.steps > 0 ? <span><span className="bold">{pair.steps}</span> <RxCross2 /> {pair.text}</span>
                : pair.text}
            </span>
            <div style={{width: `${lineWidths[index] || 0}px`, borderBottom: '1px solid #4fc0e5',
                flexGrow: 1, flexShrink: 1}}/>
            <span ref={el => rightTextRefs.current[index] = el}
              style={{ padding: '0 10px', whiteSpace: 'nowrap' }}>
              {pair.mult > 0 ? <>
                <span style={{backgroundColor: "#1eaede", color: "white", padding: "2px 10px",
                    borderRadius: "6px"}} className="bold">*{pair.mult}</span>
                <img alt="" src="/bkg/lrarrow.png" width="20px" style={{margin: arrowy + "px 4px 0px 4px"}}/>
                <span style={{display: "inline-block", width: "40px", textAlign: "center",
                    backgroundColor: "#dcf6ef", borderRadius: "6px"}}>
                    <CountUp delay={pair.delay} end={pair.mult * pair.steps} duration={5}/>
                </span>
                </> : <>
                    <img alt="" src="/bkg/lrarrow.png" width="20px" style={{margin: arrowy + "px 4px 0px -10px"}}/>
                    <span style={{display: "inline-block", width: "40px", textAlign: "center",
                        backgroundColor: "#dcf6ef", borderRadius: "6px"}}>
                        {pair.steps}
                    </span>
                </>}
            </span>
          </div>
        ))}
      </div>
    );
  };

  export default TableProgress