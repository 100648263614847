import { useEffect, useState } from "react"
import { getAPICall } from "../Components/APICall";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import HomeButton from "../Components/HomeButton";
import MagBanner from "../Components/MagBanner";
import Translate from "../AppContext/Translate";
import SchoolCover from "./SchoolCover";
import SchoolLogo from "./SchoolLogo";
import { useWindowSize } from "../AppContext/WindowSize";
import MagButton from "../Components/MagButton";

function SchoolList() {
    const [schools, setSchools] = useState([]);
    const [width,] = useWindowSize();
 
    useEffect(() => {
        getAPICall('school/list', {}, (data) => {
            if (data) setSchools(data);
        });
    }, []);

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Школи" decor={<img alt="" src="/bkg/decor/dec19.png" width="60%" style={{float: "right"}}/>}/>
        <div className="magSection" style={{backgroundColor: "#fef5de", flexDirection: "column"}}>
            <p className="bold"><Translate>Школи на нашій платформі</Translate>:</p>
            {schools.map((school, i) => {
                return <div key={i} className="magSection" style={{backgroundColor: "white", margin: "24px 0px",
                    padding: "0px 0px 8px 0px", flexDirection: "column"}}>
                    <SchoolCover school={school.id} allowChange={false}/>
                    <div className="pad12" style={{display: "flex"}}>
                        <div style={{margin: (width < 480 ? "-44": "-64") + "px 16px 0px 0px"}}>
                            <SchoolLogo schoolId={school.id} allowChange={false}
                                size={width < 480 ? 'small' : 'med'}/>
                        </div>
                        <div>
                            <p className="fmak xlarge">{school.title}</p>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <MagButton text="Знайомство зі школою" size="large"
                            clickEff={{link: '/school/' + school.id}}/>
                    </div>
                </div>
            })}
        </div>
        <div className="magSection" style={{backgroundColor: "#fef5de", justifyContent: "center"}}>
            <HomeButton/>
        </div>
        <Footer/>
    </div>
}

export default SchoolList