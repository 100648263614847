import { useState, useEffect, useContext } from "react";
import { checkEditPermission } from "./CheckEditPermission";
import { AppContext } from "../../AppContext/AppContext";
import Alert from 'react-bootstrap/Alert';
import Translate from "../../AppContext/Translate";
import BeatLoader from "react-spinners/BeatLoader";
import CabEditCourseInfo from "./CabEditCourseInfo";
import Accordion from 'react-bootstrap/Accordion';
import CabEditCourseTeam from "./CabEditCourseTeam";
import CabEditCourseOptions from "./CabEditCourseOptions";
import CabEditCoursePackages from "./CabEditCoursePackages";
import CabEditCourseIndex from "./CabEditCourseIndex";
import CabEditCourseMaterials from "./CabEditCourseMaterials";
import ActivationState from "./ActivationState";

function CabEditCourse({courseData, categories, set}) {
    const { userId, token } = useContext(AppContext);
    const [allowed, setAllowed] = useState(null);
    const [options, setOptions] = useState({});
    const [units, setUnits] = useState(null);
    const [activeKey, setActiveKey] = useState(null);

    const handleAccordionToggle = (key) => {
        setActiveKey((prevKey) => (prevKey === key ? null : key));
    };

    useEffect(() => {
        checkEditPermission(userId, token, courseData.id, (allowed) => { 
            if (allowed) setAllowed(1);
            else checkEditPermission(userId, token, courseData.id, (allowed) => {
                setAllowed(allowed ? 2 : 0);
            }, true);
        }, false);
    }, [userId, token, courseData.id]);

    const get = (field, def=-1) => {
        if (courseData) {
            if (field in courseData) return courseData[field];
            else return def;  
        } else return def;
    }

    return allowed === null ? <BeatLoader /> : (allowed === 0 ? <Alert variant="danger" style={{marginTop: "16px"}}>
        <Translate>Помилка</Translate>, <Translate>у вас немає прав на редагування курсу</Translate>.
    </Alert> : <>
        <h1 style={{marginTop: "16px"}}><Translate>Видання курсу</Translate></h1>
        <p className="bot16"><Translate>Заповніть кожен із розділів, щоб підготувати свій курс</Translate></p>
        <div style={{margin: "auto", width: "100%", maxWidth: "720px"}}>
            <Accordion activeKey={activeKey}>
                <CabEditCourseInfo get={get} set={set} courseData={courseData} categories={categories}
                    onToggle={handleAccordionToggle} activeKey={activeKey}/>
                <CabEditCourseTeam course={courseData.id}
                    onToggle={handleAccordionToggle} activeKey={activeKey}/>
                <CabEditCourseOptions course={courseData.id} options={options} setOptions={setOptions}
                    onToggle={handleAccordionToggle} activeKey={activeKey}/>
                <CabEditCoursePackages course={courseData.id} options={options}
                    onToggle={handleAccordionToggle} activeKey={activeKey}/>
                <CabEditCourseIndex course={courseData} set={set} units={units} setUnits={setUnits}
                    onToggle={handleAccordionToggle} activeKey={activeKey}/>
                <CabEditCourseMaterials course={courseData.id} units={units} numDays={courseData.numDays}
                    onToggle={handleAccordionToggle} activeKey={activeKey}/>
            </Accordion>
            <ActivationState data={courseData}/>
        </div>
    </>)
}

export default CabEditCourse