import { useEffect, useRef, useState } from "react";
import Translate from "../AppContext/Translate"

function MagBanner({ title, titleColor = 'black', bkgColor = '#d9f5ed', icon = null, decor = null, translate = true }) {
    const h1Ref = useRef(null);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        if (h1Ref.current) {
            const h1Rect = h1Ref.current.getBoundingClientRect();
            setOffset(h1Rect.left);
        }
      }, [title]);

    return <div className="magSection" style={{ backgroundColor: bkgColor, justifyContent: "center" }}>
        {icon ? <>
            {offset >= 100 &&
                <img alt="" src={icon} style={{position: "absolute", left: "-5px", transform: "translateY(-35%)",
                    zIndex: 50, maxHeight: "125%", top: "50%", maxWidth: (offset - 20) + "px"}}/>
            }
            <h1 ref={h1Ref} style={{ color: titleColor, margin: "0px", zIndex: 100 }} className="center">
                {translate ? <Translate>{title}</Translate> : title}
            </h1>
        </> :
            <h1 style={{ color: titleColor, margin: "0px" }} className="center">
                {translate ? <Translate>{title}</Translate> : title}
            </h1>
        }
        {decor && <div style={{
            position: "absolute", zIndex: 100, width: "140px", maxWidth: "25%", height: "100%",
            bottom: "-30%", right: "-20px"
        }}>
            {decor}
        </div>}
    </div>
}

export default MagBanner