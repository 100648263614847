import Translate from "../../AppContext/Translate"
import { getAltBackgroundColor, getTextColor } from "../Themes/ThemeColors"

function GeneralAbout({theme, lines}) {
    return <div style={{backgroundColor: getAltBackgroundColor(theme), color: "white"}} className="pad24">
        <div style={{width: "400px", maxWidth: "100%"}} className="center">
            <p className="center top24 xxxlarge"><Translate>ПРО ЩО?</Translate></p>
            <img alt="" src={"/bkg/decor/sept" + (theme + 1) + ".png"} width="310px" className="bot24"/>
        </div>
        {lines && lines.map((line, i) => {
            return <div key={i} style={{display: "flex", alignItems: "flex-start"}}>
                <img alt="" src={"/bkg/decor/bullet" + (theme + 1) + ".png"} width="48px" className="bot16"/>
                <div style={{textAlign: "left", marginLeft: "18px", color: getTextColor(theme)}}
                    className="bot16">{line}</div>
            </div>
        })}
        <img alt="" src="/bkg/decor/m.png" style={{position: "absolute", bottom: "10px", right: "20px",
            width: "15%", maxWidth: "214px", minWidth: "90px"}}/>
    </div>
}

export default GeneralAbout