import { useState, useRef, useEffect } from "react";
import { RiCloseLine } from 'react-icons/ri';
import SearchResults from "./SearchResults";
import { getAPICall } from "./APICall";

function SearchBar({ placeholder, size }) {
  const [kind, setKind] = useState("all");
  const [wordEntered, setWordEntered] = useState("");
  const [debouncedWord, setDebouncedWord] = useState("");
  const [people, setPeople] = useState([]);
  const [courses, setCourses] = useState([]);
  const [searchFullBar, setSearchFullBar] = useState(0);
  const inputRef = useRef();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedWord(wordEntered);
      if (wordEntered.length > 0) {
        getAPICall('user/search', { word: wordEntered }, (data) => {
          setPeople(data.users);
          setCourses(data.courses);
        }, () => { setPeople([]); setCourses([]); });
      } else { setPeople([]); setCourses([]); }
    }, 1000);
    if (wordEntered === '') document.body.style.overflow = '';
    else document.body.style.overflow = 'hidden';
    return () => {
      clearTimeout(handler);
      document.body.style.overflow = '';
    };
  }, [wordEntered]);

  const handleFilter = (event) => {
    setWordEntered(event.target.value);
  };

  const iconSearchClick = (event) => {
    setSearchFullBar(1);
  };

  const iconCloseSearchClick = (event) => {
    setSearchFullBar(0);
    clearInput();
  };

  const clearInput = () => {
    setPeople([]);
    setCourses([]);
    setWordEntered("");
    setDebouncedWord("");
  };

  const empty = (people, courses, kind) => {
    if (kind === 'all') return people.length === 0 && courses.length === 0;
    if (kind === 'people') return people.length === 0;
    return courses.length === 0;
  }

  if (size !== "big") {
    if (searchFullBar === 0) {
      return <div className='menuDivIcon'>
        <img src="/bkg/lensw.png" className="align-top pointer" height="26" alt=""
          onClick={iconSearchClick} />
      </div>
    } else {
      return (<div className="fullSearchBar bg-dark">
        <div className="search">
          <input autoFocus
            type="text"
            placeholder={placeholder}
            value={wordEntered}
            onChange={handleFilter}
            style={{ marginLeft: '12px', width: 'calc(100vw - 54px)', marginTop: "22px" }}
            ref={inputRef} />
          <RiCloseLine onClick={iconCloseSearchClick} className="closeButtonIcon" color="white" />
          <SearchResults kind={kind} setKind={setKind} people={people} courses={courses}
            wordEntered={debouncedWord} size={size} />
        </div>
      </div>
      );
    }
  } else {
    return (
      <div className="search">
        <div className="searchInputs">
          <input
            type="text"
            placeholder={placeholder}
            value={wordEntered}
            onChange={handleFilter}
            ref={inputRef}
          />
          <div className="searchIcon">
            {empty(people, courses, kind) ?
              <img alt="" src="/bkg/lens.png" height="22" onClick={() => { inputRef.current.focus() }} />
              : <RiCloseLine id="clearBtn" onClick={clearInput} style={{ cursor: "pointer" }} />
            }
          </div>
        </div>
        <SearchResults kind={kind} setKind={setKind} people={people} courses={courses}
          wordEntered={debouncedWord} size={size} />
      </div>
    );
  }
}

export default SearchBar