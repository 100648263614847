import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Translate from "../AppContext/Translate";
import { Link } from "react-router-dom";
import UserPhotoIcon from "./UserPhotoIcon";

function SearchSubitem(props) {
    const addr = "/load/course/" + props.id;
    const pic = "/courses/cover" + props.id + props.ext;
    if (props.size === "tiny") {
        return (
            <li className="col-sm-6 col-md-3 col-lg-2" style={{ padding: '10px' }}>
                <Link to={addr}>
                    <div style={{ width: '100%', display: 'flex', alignItems: "flex-start" }}>
                        <img alt="" src={pic} width="50%" style={{ marginRight: '12px' }} />
                        <div>
                            <p className="boldWhiteHoverRed" style={{ marginTop: '10px' }}>{props.title}</p>
                            <p style={{ color: '#b8b7b7', marginTop: '24px' }}>
                                <Translate>Курс від</Translate>
                                {' '}{props.school}
                            </p>
                        </div>
                    </div>
                </Link>
            </li>
        );
    } else {
        return (
            <li className="col-sm-6 col-md-3 col-lg-2" style={{ padding: '10px' }}>
                <Link to={addr}>
                    <div className="col-5 col-sm-12 boldWhiteHoverRed">
                        <img alt="" src={pic} width="100%" />
                        <p style={{ marginTop: '10px' }}>{props.title}</p>
                    </div>
                </Link>
                <p style={{ color: '#b8b7b7' }}>
                    <Translate>Курс від</Translate>
                    {' '}{props.school}
                </p>
            </li>
        );
    }
}

function UserSubitem({id, name}) {
    return <li className="col-sm-6 col-md-3 col-lg-2" style={{ padding: '10px' }}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <UserPhotoIcon user={id} userName={name} big={true}/>
            <p>{name}</p>
        </div>
    </li>
}

function SearchResults({ kind, setKind, people, courses, wordEntered, size }) {
    const maxElements = 48;

    return wordEntered !== '' && <div className="dataResult">
        <div style={{ height: "4px" }} />
        <Tab.Container id="tabs-search" defaultActiveKey="all">
            <Nav variant="pills" onSelect={(e) => { setKind(e); }}>
                <Nav.Item>
                    <Nav.Link eventKey="all"><Translate>Все</Translate></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="courses"><Translate>Курси</Translate></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="people"><Translate>Користувачі</Translate></Nav.Link>
                </Nav.Item>
            </Nav>
        </Tab.Container>
        <hr style={{ marginTop: "8px" }} />
        {(kind === "all" || kind === "courses") && <div className="top12">
            <p>{courses.length >= maxElements && <Translate>більше</Translate>}{' '}{courses.length}{' '}
                <Translate>курси для</Translate> '{wordEntered}'
            </p>
            {courses.length > 0 && <ol className="olDataResult">
                {
                    courses.slice(0, maxElements).map((element, index) => {
                        return <SearchSubitem size={size} key={index} title={element.title} id={element.id}
                            school={element.school} ext=".jpg" />
                    })
                }
            </ol>}
        </div>}
        {(kind === "all" || kind === "people") && <div className="top12">
            <p>{people.length >= maxElements && <Translate>більше</Translate>}{' '}{people.length}{' '}
                <Translate>користувачі для</Translate> '{wordEntered}'
            </p>
            {people.length > 0 && <ol className="olDataResult">
                {
                    people.map((element, index) => {
                        return <UserSubitem key={index} id={element.id} name={element.name} />
                    })
                }
            </ol>}
        </div>}
        <div style={{ height: "64px" }} />
    </div>
}

export default SearchResults