import { postAPICall, postAPIUploadProgress } from "./APICall";
import WriteHelper from "./WriteHelper";

function falseArray(size) {
    var v = [];
    for (var i = 0; i < size; i++) v.push(false);
    return v;
}

const getNonProcessedIndex = (items) => {
    for (var i = 0; i < items.length; i++) {
        if (!items[i]) {
            items[i] = true;
            return i;
        }
    }
    return -1;
}

function trackback(items, paths, processedFiles, userId, token, onError) {
    for (var i = 0; i < items.length; i++) {
        const item = items[i];
        for (var j = 0; j < processedFiles.length; j++) {
            if (processedFiles[i][j]) {
                const file = item.files[j];
                if (!file.deleted && 'id' in file && file.buffer !== null) {
                    postAPICall('cabinet/delete', {userId: userId, token: token,
                        path: paths[i], videoId: file.id}, () => {});
                }
            }
        }
    }
    onError();
}

function saveText(itemIndex, items, paths, processedItems, processedFiles, setProgress, userId, token, apiData,
    onSuccess, onError, onData) {
    const desc = items[itemIndex].text;
    var text = WriteHelper.encode(WriteHelper.filterSimpleText(desc));
    text = WriteHelper.addAttachedFiles(text, items[itemIndex].files, (file) => {
        if (file.isVideo() && "id" in file && file.id >= 0) return file.id;
        return file.name;
    });
    postAPICall(apiData[itemIndex].apifnc, {...apiData[itemIndex].params, text: text}, (data) => {
        if (onData) onData(data);
        for (var i = 0; i < processedFiles[itemIndex].length; i++) processedFiles[itemIndex][i] = false;
        processItems(items, paths, processedItems, processedFiles, setProgress, userId, token, apiData, onSuccess, onError);
    }, (data) => { 
        trackback(items, paths, processedFiles, userId, token, onError);
    });
}

function processFiles(itemIndex, items, paths, processedItems, processedFiles, setProgress, userId, token, apiData,
    onSuccess, onError, onData) {
    const fileIndex = getNonProcessedIndex(processedFiles[itemIndex]);
    if (fileIndex >= 0) { // Update file
        var file = items[itemIndex].files[fileIndex];
        const path = paths[itemIndex];
        if (file.deleted) {
            setProgress({file: file.name, percent: 0, action: 'del'});
            const videoId = file.kind === 'v' ? file.name : -1;
            postAPICall('cabinet/delete', {userId: userId, token: token, path: path, videoId: videoId}, () => {
                setProgress({file: file.name, percent: 100, action: 'del'});
                processFiles(itemIndex, items, paths, processedItems, processedFiles, setProgress, userId, token, apiData,
                    onSuccess, onError, onData);
            }, () => { 
                setProgress({file: file.name, percent: 100, action: 'del'});
                processFiles(itemIndex, items, paths, processedItems, processedFiles, setProgress, userId, token, apiData,
                    onSuccess, onError, onData);
            });
        } else if (!file.isUploaded()) {
            setProgress({file: file.name, percent: 0, action: 'upl'});
            if (file.buffer === null) {
                processFiles(itemIndex, items, paths, processedItems, processedFiles, setProgress, userId, token, apiData,
                    onSuccess, onError, onData);
            } else {
                postAPIUploadProgress('cabinet/upload', {name: file.name, buffer: file.source.buffer},
                    {userId: userId, token: token, path: path, toProcess: file.isVideo()},
                    (data) => {
                        file.id = data.id; 
                        file.uploaded = 1;
                        processFiles(itemIndex, items, paths, processedItems, processedFiles, setProgress, userId, token,
                            apiData, onSuccess, onError, onData); },
                    (data) => { 
                        setProgress(null);
                        trackback(items, paths, processedFiles, userId, token, onError); },
                    (percent) => { setProgress({file: file.name, percent: percent, action: 'upl'});
                });
            }
        } else {
            processFiles(itemIndex, items, paths, processedItems, processedFiles, setProgress, userId, token, apiData,
                onSuccess, onError, onData);
        }
    } else { // Save text
        saveText(itemIndex, items, paths, processedItems, processedFiles, setProgress, userId, token, apiData,
            onSuccess, onError, onData);
    }
}

function processItems(items, paths, processedItems, processedFiles, setProgress, userId, token, apiData,
    onSuccess, onError, onData) {
    const index = getNonProcessedIndex(processedItems);
    if (index >= 0) {
        processFiles(index, items, paths, processedItems, processedFiles, setProgress, userId, token,
            apiData, onSuccess, onError, onData);
    } else { // End
        setProgress(null);
        onSuccess();
    }
}

async function rtEditorUploader(items, paths, setProgress, userId, token, apiData, onSuccess, onError, onData=null) {
    var processedItems = falseArray(items.length);
    var processedFiles = [];
    for (var i = 0; i < items.length; i++) processedFiles.push(falseArray(items[i].files.length));
    processItems(items, paths, processedItems, processedFiles, setProgress, userId, token, apiData, onSuccess, onError,
        onData);
}

export default rtEditorUploader