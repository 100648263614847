import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { MediaPath } from "../../Components/MediaPath";
import WriteHelper from "../../Components/WriteHelper";
import { formatDateTime, translate } from "../../AppContext/Translate";
import { postAPICall, postAPIUpload } from "../../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import ChooseFileButton from "./ChooseFileButton";
import InlineWriteComment from "./InlineWriteComment";
import { RiFileEditLine } from 'react-icons/ri';
import { BiCommentEdit } from 'react-icons/bi';
import DocPreview from "../../Components/DocPreview";
import { MimeIconSmall } from "../../Components/MimeIcon";
import TaskReview from "./TaskReview";

function Delivery({delivery, user, task, markTaskAsSent, userName, group, packageId, updateDelivery, canSend}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [fileSrc, setFileSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    const [changingComment, setChangingComment] = useState(false);
    const [comment, setComment] = useState(null);
    const [message, setMessage] = useState("");
    const [changingFile, setChangingFile] = useState(false);

    const own = user === userId;
    var path = new MediaPath(MediaPath.kind_privateTaskDelivery);
    path.add(user);
    path.add(packageId);
    path.add(task.post);

    const onPreview = (data) => {
        setPreview(data);
    }

    useEffect(() => {
        const getDeliveryText = () => {
            return delivery && delivery.length > 0 ? delivery[0].comment : "";
        }
        function updateComment(text) {
            const elements = WriteHelper.parseElements(text, path, userId, token, 'textPost', onPreview);
            const msg = WriteHelper.parseMessage(text, path, userId, token);
            setComment(elements);
            setMessage(msg.text);
        }
        updateComment(getDeliveryText());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delivery]);

    function fileChange(evnt) {
        if (!evnt) { setFileSrc(null); return; }
        const file = evnt.target.files[0];
        if (!file) { setFileSrc(null); return; }
        const size = file.size / 1048576; 
        if (size > 10) {
            setFileSrc({'error': translate("Помилка", dicLang) + ": " + translate("файли розміром понад 10 Мб не можна завантажувати", dicLang)});
        } else {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    setFileSrc({name: file.name, size: size, buffer: result, kind: 'f'});
                } else {
                    setFileSrc({'error': translate("Помилка", dicLang) + ": " + translate("не вдалося завантажити файл", dicLang)});
                }
            }
            fileReader.readAsDataURL(file);
        }
    }

    const onUpdateFileSuccess = (data) => {
        setLoading(false);
        updateDelivery();
    }

    const onUpdateFileError = (data) => {
        setLoading(false);
        alert(translate("Помилка", dicLang) + ": " + translate("завдання не вдалося надіслати", dicLang));
        updateDelivery();
    }

    function updateFile(evnt) {
        setChangingFile(false);
        if (!evnt) { alert(translate("Ваш файл не було змінено", dicLang) + "."); return; }
        const file = evnt.target.files[0];
        if (!file) { alert(translate("Ваш файл не було змінено", dicLang) + "."); return; }
        const size = file.size / 1048576; 
        if (size > 10) {
            alert(translate("Помилка", dicLang) + ": " + translate("файли розміром понад 10 Мб не можна завантажувати", dicLang));
            return;
        }
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
                const fileData = {name: file.name, size: size, buffer: result, kind: 'f'};
                setFileSrc(fileData);
                setLoading(true);
                postAPIUpload('tasks/updateFile', fileData, {userId: userId, token: token, packageId: packageId,
                    postId: task.post, course: task.course}, onUpdateFileSuccess, onUpdateFileError);
            } else {
                alert(translate("Помилка", dicLang) + ": " + translate("не вдалося завантажити файл", dicLang));
            }
        }
        fileReader.readAsDataURL(file);        
    }

    const onSendSuccess = (data) => {
        setLoading(false);
        setFileSrc(null);
        markTaskAsSent(task.post);
    }

    const onSendError = (data) => {
        setLoading(false);
        setFileSrc({'error': translate("Помилка", dicLang) + ": " + translate("завдання не вдалося надіслати", dicLang)});
    }

    const send = (html) => {
        setLoading(true);
        const fileData = fileSrc;
        postAPIUpload('tasks/post', fileData, {userId: userId, token: token, groupId: group, packageId: packageId,
            text: WriteHelper.encode(html), postId: task.post, course: task.course}, onSendSuccess, onSendError);
    }

    const onUpdateSuccess = (data) => {
        setLoading(false);
    }

    const onUpdateError = (data) => {
        setLoading(false);
        alert(translate("Помилка", dicLang) + ": " + translate("ваш коментар не вдалося оновити", dicLang) + ".");
        updateDelivery();
    }

    function parseElements(text) {
        const elements = WriteHelper.parseElements(text, path, userId, token, 'textPost', onPreview);
        setComment(elements);
    }

    const updateComment = (html) => {
        setLoading(true);
        setChangingComment(false);
        const msg = WriteHelper.encode(html);
        setMessage(html);
        parseElements(msg);
        postAPICall('tasks/updateComment', {userId: userId, token: token, packageId: packageId,
            text: WriteHelper.encode(html), postId: task.post, course: task.course}, onUpdateSuccess, onUpdateError);
    }

    const changeComment = () => {
        setChangingComment(true);
    }

    const changeFile = () => {
        setChangingFile(true);
    }

    if (!canSend) {
        return <div style={{marginBottom: "24px"}}>
            <p className="textPost boldPost">{translate("Вибраний вами пакет не дозволяє надсилати завдання", dicLang)}</p>
        </div>
    } else if (delivery.length === 0) {
        return <div className="pad16 bot32">
            {loading ? <BeatLoader size={24} color="blueviolet"/> :
                <p className="textPost boldPost bot16">{translate("Жодне завдання ще не відправлено", dicLang)}.</p>
            }
            {own && !loading && <>
                <ChooseFileButton ext={task.extensions} fileSrc={fileSrc} fileChange={fileChange} />
                {fileSrc && !('error' in fileSrc) &&
                <InlineWriteComment send={send} />
                }
            </>}
        </div>
    } else {
        return <>
        {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} path={path}/>} 
        <div className="chatPost" style={{backgroundColor: "whitesmoke"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{fontWeight: "600"}}>{userName}</div>
                <div className="chatUserName">{formatDateTime(delivery[0].lastUpdate)}</div>
            </div>
            <hr style={{marginTop: "8px"}}/>
            {changingFile ?
            <ChooseFileButton ext={task.extensions} fileSrc={null} fileChange={updateFile} cancel={true} />
            : <div style={{display: "flex", justifyContent: "center"}}>
                {loading ? <BeatLoader size={24} color="blueviolet"/> :
                <MimeIconSmall name={delivery[0].file} onPreview={onPreview}/> }
            </div>
            }
            <div style={{padding: "8px"}}>
            {changingComment ?
            <InlineWriteComment text={message} send={updateComment}/>
            : <>{comment}</>
            }
            </div>
            {own && !changingComment && !changingFile && <>
            <hr style={{marginBottom: "8px"}}/>
            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                <button className="ChatToolButton" onClick={changeComment}>
                    <BiCommentEdit size={20}/>
                    <span style={{marginLeft: "6px", fontSize: "small"}}>{translate("Редагувати коментар", dicLang)}</span>
                </button>
                <button className="ChatToolButton" onClick={changeFile}>
                    <RiFileEditLine size={20}/>
                    <span style={{marginLeft: "6px", fontSize: "small"}}>{translate("Оновити файл", dicLang)}</span>
                </button>
            </div>
            </>}
        </div>
        <TaskReview user={user} post={task.post} group={group} packageId={packageId}/>
        </>
    }
}

export default Delivery