import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext/AppContext";
import { postAPICall, getAPICall } from "../Components/APICall";
import { Navigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import Container from "react-bootstrap/Container";
import Translate from "../AppContext/Translate";
import BeatLoader from "react-spinners/BeatLoader";
import { GiPresent } from "react-icons/gi";
import PostMessage from "../Components/PostMessage";
import { AiOutlineGift } from "react-icons/ai";
import { translateWithNumber } from "../AppContext/Translate";
import { TbAward } from 'react-icons/tb';
import { Link } from "react-router-dom";

function PresentDiscount({discount}) {
    const { dicLang } = useContext(AppContext);
    return <>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "floralwhite",
            marginTop: "16px"}}>
            <img alt="" src="/icons/discount.png"
                style={{width: "92px", marginRight: "24px"}}/>
            <div>
                <p className="cartItemTitle">{translateWithNumber("*% знижка на наступну покупку", discount, dicLang)}</p>
            </div>
        </div>
        <p style={{margin: "24px"}}><Translate>Ви можете отримати доступ до всіх своїх знижок, натиснувши на меню</Translate>: <TbAward/>
            <Link to="/user/rewards"><i><Translate>Мої винагороди</Translate></i></Link></p>
    </>
}

function PresentItem({item}) {
    const { lang } = useContext(AppContext);
    const [title, setTitle] = useState(null);
    useEffect(() => {
        getAPICall('shop/item', {lang: encodeURIComponent(lang), item: item},
        (data) => {
            if (data && data.length > 0) {
                const row = data[0];
                setTitle(<PostMessage msg={row.name} open={false} sep={false} textStyle="cartItemTitle"/>);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, lang]);
    return <>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "floralwhite",
            marginTop: "16px"}}>
            <img className="presentImage" alt="" src={"/items/item" + item + ".jpg"}
                style={{width: "128px", height: "128px", marginRight: "24px"}}/>
            <div>
                {title && <div>{title}</div>}
            </div>
        </div>
        <p style={{margin: "24px"}}><Translate>Ви можете отримати доступ до всіх своїх товарів, натиснувши на меню</Translate>: <AiOutlineGift/>
            <Link to="/user/products"><i><Translate>Мої товари</Translate></i></Link></p>
    </>
}

function UserPresent() {
    const { userId, token, lang } = useContext(AppContext);
    const [error, setError] = useState(false);
    const [chestClicked, setChestClicked] = useState(0);
    const [newRank, setNewRank] = useState(-1);
    const [present, setPresent] = useState(null);

    useEffect(() => {
        const errorFnc = () => {
            setError(true);
        }

        const getStepsInfo = (data) => {
            const rankInfo = Math.floor(data.stepsDone / 7);
            if (rankInfo <= 0) errorFnc();
            else {
                setNewRank(rankInfo);
                //console.log(JSON.stringify({userId: userId, token: token, newRank: rankInfo, lang: lang}));
                postAPICall('game/present', {userId: userId, token: token, newRank: rankInfo, lang: lang},
                    receivePresent, errorFnc);
            }
        }
        
        const receivePresent = (data) => {
            if (data && "present" in data) setPresent(data);
            //console.log(JSON.stringify(data));
        }

        if (userId < 0) errorFnc();
        else {
            postAPICall('game/updatedSteps', {userId: userId, token: token}, getStepsInfo, errorFnc);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token]);

    const chestClick = () => {
        setChestClicked(1);
        setTimeout(() => {
            setChestClicked(2);
        }, 3800);
    }
    
    return error ? <Navigate to="/user/rewards" replace={true} /> :
    <div className='mainContainer'>
        <Menu />
        <Container className="userProfileForm" style={{textAlign: "center"}}>
            {!present ? <BeatLoader size="20px"/>
            : <>
            <h2><Translate>Щиро вітаємо!</Translate></h2>
            <h3><Translate>Ви отримали нову винагороду</Translate></h3>
            <hr/>
            {chestClicked < 2 ? <>
                <p><Translate>Натисніть на скарбничку, щоб відкрити її</Translate>:</p>
                <hr/>
                {chestClicked < 1 ?
                    <div className="divChest" style={{cursor: "pointer"}} onClick={chestClick}>
                        <img className="imgChest" src="/bkg/chest.jpg" alt=""/>
                    </div> :
                    <div className="divChest">
                        <img className="imgChest" src="/bkg/chest.gif" alt=""/>
                    </div>
                }
                </> :
                <>
                <h3 style={{color: "cadetblue"}}><Translate>Досягнуто новий рівень</Translate>:</h3>
                <div style={{fontSize: "x-large", fontFamily: "Mak", color: "#434476"}}>
                    <img src={"/icons/rank" + newRank + ".png"} alt="" width="128px" style={{marginRight: "8px",
                        filter: "drop-shadow(2px 2px 1px #00000042)"}}/>
                    <Translate>Рівень</Translate> {newRank + 1}
                </div>
                <h3 style={{color: "cadetblue", marginTop: "24px"}}>
                    <GiPresent size="44" style={{marginTop: "-16px"}}/>
                    <Translate>Новий подарунок за ваш прогрес</Translate>:</h3>
                {present.present === 0 ? <PresentDiscount discount={present.discount}/> :
                    <PresentItem item={present.item}/>}
                </>
            }
            </>
            }
        </Container>
        <Footer/>
    </div>
}

export default UserPresent