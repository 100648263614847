import { lazy, Suspense } from 'react';
import { getBackgroundImageColor } from '../Themes/ThemeColors';

const BackgroundImages = lazy(() => import('../Themes/BackgroundImages'));

function GeneralCover({width, backImage, frame, theme, category}) {
    return <div style={{width: width >= 720 ? "50%" : "100%", maxWidth: "520px", margin: "auto"}}>
        <Suspense>
            <BackgroundImages id={backImage + 1}
                sty={{color: getBackgroundImageColor(theme), width: "100%", height: "auto"}}/>
        </Suspense>
        <img alt="" src={"/bkg/themes/c" + category + "_t" + (theme + 1) + "_" + (frame + 1) + ".png"}
            style={{width: "90%", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}/>
    </div>
}

export default GeneralCover