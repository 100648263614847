import Menu from "../Menu/Menu"
import { useEffect, useContext, useState } from "react"
import { AppContext } from "../AppContext/AppContext";
import { Navigate } from "react-router-dom";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import CourseList from "../Lists/CourseList";
import UserListCourses from "./UserListCourses";
import Footer from "../Components/Footer";
import HomeButton from "../Components/HomeButton";
import CabHeader from "../Cabinet/CabHeader";
import MyProfileButton from "../Components/MyProfileButton";
import MagBanner from "../Components/MagBanner";

function UserCourses() {
    const { userId, userName, token, lang } = useContext(AppContext);
    const [courses, setCourses] = useState([]);
    const [approvals, setApprovals] = useState([]);

    useEffect(() => {
        const plansInfoSuccess = (data) => {
            if (data && data.length > 0) {
                setCourses(data);
            }
        }

        const approvalSuccess = (data) => {
            if (data && data.length > 0) {
                setApprovals(data);
            }
        }

        if (userId !== -1) {
            getAPICall('user/purchasedPlans', {userId: userId, token: encodeURIComponent(token),
                lang: encodeURIComponent(lang)}, plansInfoSuccess);
            getAPICall('project/approval', {userId: userId, token: encodeURIComponent(token)}, approvalSuccess);
        }
    }, [userId, token, lang]);

    return userId >= 0 ? 
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu />
        <MagBanner title="Мої курси" bkgColor="#fee6dd" decor={<>
            <img alt="" src="/bkg/decor/dec7.png" width="100%"/>
            <img alt="" src="/bkg/decor/dec6.png" width="20%" style={{marginLeft: "70%"}}/>
        </>}/>
        <div className="magSection" style={{backgroundColor: "#fef5de", flexDirection: "column"}}>
            <CabHeader user={userId} userName={userName}/>
            <div className="userProfileContent">
                {courses.length === 0 ? <>
                    <p style={{fontWeight: "600", fontStyle: "italic", marginTop: "16px"}}><Translate>Ви ще не купили жодного курсу</Translate>.</p>
                    <p><Translate>Оберіть курси, які вам подобаються та навчайтеся у найкращих професіоналів</Translate>.</p>
                    <div style={{marginLeft: "-12px", marginRight: "-24px"}}>
                        <CourseList title="Курси, які можуть вас зацікавити"/>
                    </div>
                </> :
                <UserListCourses courses={courses} approvals={approvals}/>
                }
            </div>
        </div>
        <div className="magSection" style={{justifyContent: "space-evenly", flexWrap: "wrap", backgroundColor: "#fef5de"}}>
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div> :
    <Navigate to="/" replace={true} />
}

export default UserCourses