import { useParams } from "react-router-dom";
import Menu from "../Menu/Menu"
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import Footer from "../Components/Footer";
import Decline from "./Decline";
import ThankYou from "./ThankYou";
import SyncLoader from "react-spinners/SyncLoader";

function PurchaseDone() {
    var orderId = useParams()["id"];
    const { userId, token, removeFromCart } = useContext(AppContext);
    const [accepted, setAccepted] = useState(!orderId ? false : null);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (orderId) {
                getAPICall('order/paid', {userId: userId, token: encodeURIComponent(token), orderId: orderId},
                    (data) => {
                        if (data && data.length > 0) {
                            var res = data[0].paid === 1;
                            setAccepted(res);
                            if (res) removeFromCart(-1);
                        } else setAccepted(false);
                    }, () => { setAccepted(false); });
            }
        }, 3000);
        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, userId, token]);

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        {accepted === null ? <div className="pad12">
            <SyncLoader/>
        </div> : (accepted === true ? <ThankYou/> : <Decline/>)}
        <Footer/>
    </div>
}

export default PurchaseDone