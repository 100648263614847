import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Translate from "../../../AppContext/Translate";

const getCategoryName = (id, categories) => {
    if (categories) {
        for (var i = 0; i < categories.length; i++) {
            if (categories[i].link === id) {
                return <>
                    <img alt="" src={'/categories/cat' + id + '.png'} width="24" height="24" />
                    {' ' + categories[i].title}</>;
            }
        }
    }
    return '';
}

function SelectCategory({courseId, categories, category, set, setChanges}) {
    return <div style={{display: "flex", alignItems: "baseline", marginTop: "6px", marginBottom: "8px"}}>
        <Form.Label style={{marginRight: "8px"}}><Translate>Категорія</Translate></Form.Label>
        <Dropdown onSelect={(eventkey, event) => { 
            set(courseId, 'category', parseInt(event.target.id));
            setChanges(true); }}>
            <Dropdown.Toggle variant="outline-dark" id="dropdown-category">
                {getCategoryName(category, categories)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {categories && categories.map((cat, i) => {
                return <Dropdown.Item key={i} id={cat.link}>{cat.title}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    </div>
}

export default SelectCategory