import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext/AppContext";
import Translate, { translate } from "../AppContext/Translate";
import { useWindowSize } from "../AppContext/WindowSize";
import Footer from "../Components/Footer"
import MagBanner from "../Components/MagBanner"
import Menu from "../Menu/Menu"
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { getAPICall, postAPICall } from "../Components/APICall";
import MagButton from "../Components/MagButton";
import { confirm } from "../Components/ConfirmFunction";
import HomeButton from "../Components/HomeButton";

const SERVICES = ["Редагування відео", "Налаштування курсу", "Повідомте про проблему",
    "Надсилайте пропозиції щодо покращення"];

function Services() {
    const { userId, token, dicLang } = useContext(AppContext);
    const [courses, setCourses] = useState([]);
    const [service, setService] = useState(-1);
    const [course, setCourse] = useState(null);
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);
    const [width,] = useWindowSize();

    useEffect(() => {
        if (userId >= 0) {
            getAPICall('cabinet/myschool', {userId: userId, token: encodeURIComponent(token)}, 
            (data) => {
                if (data && data.length > 0) {
                    const id = data[0].id; 
                    getAPICall('school/courses', {school: id, active: -1}, (data) => {
                        setCourses(data);
                    });
                }
            });
        }
    }, [userId, token]);

    const requiresCourse = (s) => {
        return s === 0 || s === 1;
    }

    const ready = (s, c, t) => {
        return requiresCourse(s) ? s >= 0 && c !== null && t.length > 10 : s >= 0 && t.length > 10;
    }

    const sendOk = (data) => {
        if ('error' in data && data.error === 'none') setSent(true);
        else sendError();
    }

    const sendError = () => {
        alert(translate('Під час надсилання форми сталася помилка. Повторіть спробу пізніше або напишіть до нашого центру підтримки.', dicLang));
    }

    const send = async (service, course, message) => {
        if (await confirm({confirmation: translate('Ви впевнені, що бажаєте надіслати форму?', dicLang)})) {
            postAPICall('user/sendForm', {userId: userId, token: token, serviceId: service,
                service: SERVICES[service], courseId: course ? course.id : -1, course: course ? course.title : '',
                message: message}, sendOk, sendError);
        }
    }

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Послуги Платформи" icon="/icons/services.png" bkgColor="#d9eaf5"
            decor={<img alt="" src="/bkg/decor/dec17.png" width="60%" style={{float: "right"}}/>}/>
        <div className="pad12" style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
            <div className="magSection" style={{width: width >= 920 ? "45%" : "100%", backgroundColor: "#d9f5ed",
                flexDirection: "column"}}>
                <h2><Translate>Контакти</Translate></h2>
                <p className="top12" style={{color: "#869199"}}><Translate>Адреса</Translate>:</p>
                <p className="top12">Дніпро, Дніпропетровська обл,<br/>Україна, 49000</p>
                <p className="top24" style={{color: "#869199"}}><Translate>Підтримаємо</Translate>:</p>
                <p className="top12">
                    <a className="blackTextHover" href="mailto:support@magistrika.com">support@magistrika.com</a>
                </p>
            </div>
            <div className="magSection" style={{width: width >= 920 ? "45%" : "100%", backgroundColor: "#fef5de",
                display: "block"}}>
                <h2><Translate>Послуги</Translate></h2>
                {sent ? <>
                    <p className="bold top12"><Translate>Форму успішно надіслано</Translate>.</p>
                    <p className="top12 bot24"><Translate>Наші консультанти вивчать ваш запит і найближчим часом зв'яжуться з вами електронною поштою. Дякую.</Translate></p>
                    <HomeButton/>
                </> :
                (userId >= 0 ? <div className="top12" style={{ display: 'block' }}>
                    <Dropdown style={{ backgroundColor: 'white', display: 'inline-block' }}>
                        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" 
                            style={{color: service < 0 ? "#869199" : "black"}}>
                            {service < 0 ? 'Виберіть послугу' : <Translate>{SERVICES[service]}</Translate>}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {SERVICES.map((service, i) => { 
                                return <Dropdown.Item key={i} onClick={() => { setService(i); }}>
                                    <Translate>{service}</Translate>
                                </Dropdown.Item>})}
                        </Dropdown.Menu>
                    </Dropdown>
                    <p style={{height: "12px"}}/>
                    {requiresCourse(service) && <Dropdown style={{ backgroundColor: 'white', display: 'inline-block' }}>
                        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" 
                            style={{color: course ? "black" : "#869199"}}>
                            {course ? course.title : 'Виберіть курс у своїй школі'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {courses.length > 0 ? courses.map((c, i) => {
                                return <Dropdown.Item key={i} onClick={() => { setCourse(c); }}>
                                    {c.title}
                                </Dropdown.Item>
                            })
                            : <Dropdown.Item><Translate>Ви ще не створили жодного курсу</Translate></Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>}
                    <p className="top12 bot8"><Translate>{service < 2 ? "Опишіть, що вам потрібно" :
                        "Опишіть свою проблему або пропозицію"}</Translate>:</p>
                    <Form.Control value={message} type="text" as="textarea" maxLength={255} id="inputMsg" rows="5"
                        onChange={(e) => { setMessage(e.target.value); }} />
                    <div style={{display: "flex", justifyContent: "center"}} className="top12">
                        {ready(service, course, message) ?
                        <MagButton text="Надіслати" size="large" clickEff={{customFnc: () => {
                            send(service, course, message); }}}/>
                        : <MagButton text="Надіслати" size="large" bkgColor="gray" addStyle={{cursor: "auto"}}/>}
                    </div>
                </div> : <>
                    <p><Translate>Ви повинні створити обліковий запис, щоб запитувати додаткові послуги або повідомляти про проблеми чи пропозиції.</Translate>.</p>
                    <div style={{display: "flex", justifyContent: "center"}} className="top12">
                        <MagButton text="Створити акаунт" size="large" clickEff={{customFnc: () => {
                            document.getElementById("menuCreateAccountButton").click();
                        }}}/>
                    </div>
                </>)}
            </div>
        </div>
        <Footer/>
    </div>
}

export default Services