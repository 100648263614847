import { useContext, useEffect, useState } from "react";
import Translate from "../AppContext/Translate"
import Form from 'react-bootstrap/Form';
import { AppContext } from "../AppContext/AppContext";
import { postAPICall } from "../Components/APICall";
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";

function Faq({ index, category, faqs }) {
    const parseTextToElements = (text) => {
        const elements = [];
        const parts = text.split('|');
        let isLink = false;
        let i = 0;
        while (i < parts.length) {
            if (isLink) {
                const linkText = parts[i];
                const href = parts[i + 1];
                elements.push(<Link key={elements.length} to={'/' + href}>{linkText}</Link>);
                i += 2;
            } else {
                const spanText = parts[i];
                elements.push(<span key={elements.length}>{spanText}</span>);
                i += 1;
            }
            isLink = !isLink;
        }
        return elements;
    };

    return <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header>{category}</Accordion.Header>
        <Accordion.Body>
            <ul>
                {faqs.map((row, i) => {
                    return <li key={i}>
                        <b>{row[0]}</b><br />
                        {parseTextToElements(row[1])}
                    </li>
                })}
            </ul>
        </Accordion.Body>
    </Accordion.Item>
}

function SectionFaq() {
    const { lang } = useContext(AppContext);
    const [search, setSearch] = useState('');
    const [faqs, setFaqs] = useState(null);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (search.length > 0) {
                const text = search.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, " ").toLowerCase();
                postAPICall('user/faq', { text: text, lang: lang }, (data) => {
                    if (data && 'faqs' in data) {
                        if (data.faqs.length > 0) {
                            const dictionary = data.faqs.reduce((acc, item) => {
                                const { category, question, answer } = item;
                                if (!acc[category]) {
                                    acc[category] = [];
                                }
                                acc[category].push([question, answer]);
                                return acc;
                            }, {});
                            setFaqs(dictionary);
                        } else setFaqs({});
                    }
                }, () => { setFaqs({}); });
            } else setFaqs(null);
        }, 1000);
        return () => {
            clearTimeout(handler);
        };
    }, [search, lang]);

    const numFaqs = faqs ? Object.keys(faqs).length : -1;

    return <div style={{ padding: "48px", backgroundColor: "white" }}>
        <img alt="" src="/bkg/decor/dec12.png" style={{
            position: "absolute", width: "10%", maxWidth: "120px",
            bottom: "0px", left: "0px", zIndex: 0
        }} />
        <h2 style={{ fontSize: "clamp(1.6rem, 4vw, 3rem)", position: "relative", zIndex: 100 }}><Translate>часто задавані питання</Translate></h2>
        <p className="bot8"><Translate>Напишіть текст для пошуку</Translate>:</p>
        <div style={{ zIndex: 100 }}>
            <Form.Control type="text" style={{ width: "480px", maxWidth: "90%" }} value={search}
                onChange={(e) => { setSearch(e.target.value); }} maxLength={64} />
            {numFaqs >= 0 && (numFaqs === 0 ?
                <p className="italic" style={{ color: "#f76429" }}>
                    <img alt="" src="/icons/sad.png" width="24px" className="right6" />
                    <Translate>Результатів не знайдено</Translate>.</p>
                : <Accordion defaultActiveKey="0" style={{ width: "640px", maxWidth: "100%", marginTop: "24px" }}>
                    {Object.keys(faqs).map((cat, i) => {
                        return <Faq key={i} index={i} category={cat} faqs={faqs[cat]} />
                    })}
                </Accordion>)}
        </div>
    </div>
}

export default SectionFaq