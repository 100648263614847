import Dropdown from 'react-bootstrap/Dropdown';
import Translate, { getLanguagesData } from '../../../AppContext/Translate';

function SelectLang({set, get, courseId, setChanges}) {
    return <div style={{display: "flex", alignItems: "baseline", marginTop: "16px"}}>
        <span style={{marginRight: "8px"}}><Translate>Мова</Translate></span>
        <Dropdown>
            <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" style={{maxWidth: "100%"}}>
                {get('lang', 'ua')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {getLanguagesData().map((m, i) => { 
                    return <Dropdown.Item key={i} onClick={() => { 
                        set(courseId, 'lang', m.id);
                        setChanges(true); }}>{m.name}</Dropdown.Item>})}
            </Dropdown.Menu>
        </Dropdown>
        <img alt="" src={"/lang/" + get('lang', 'ua') + ".png"} width={24} style={{marginLeft: "8px"}}/>
    </div>
}

export default SelectLang