import { useEffect, useState, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Translate, { translate } from '../../AppContext/Translate';
import { BsPeopleFill } from "react-icons/bs";
import { AppContext } from '../../AppContext/AppContext';
import { getAPICall, postAPICall } from '../../Components/APICall';
import TeamMember from './TeamMember';
import { confirm } from "../../Components/ConfirmFunction";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";

function CabEditCourseTeam({course, onToggle, activeKey}) {
    var { userId, token, dicLang } = useContext(AppContext);
    const [isDirector, setIsDirector] = useState(false);
    const [team, setTeam] = useState([]);
    const [email, setEmail] = useState('');
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        getAPICall('course/team', {course: course}, (data) => {
            if (data) {
                setTeam(data);
            }
        });
        getAPICall('school/courseDirector', {courseId: course}, (data) => {
            if (data && data.length > 0) {
                setIsDirector(data[0].adminUser === userId);
            }
        });
    }, [course, userId]);

    const setRole = (index, role) => {
        const nextTeam = team.map((m, i) => {
            if (i === index) {
                var copy = {...m};
                copy.role = role;
                return copy;
            }
            else return m;
        });
        setTeam(nextTeam);
    }

    const setPercentage = (index, value) => {
        const nextTeam = team.map((m, i) => {
            if (i === index) {
                var copy = {...m};
                copy.percentage = value;
                return copy;
            }
            else return m;
        });
        setTeam(nextTeam);
    }

    const setMain = (index, main) => {
        if (main === 1) {
            var mainIndex = -1;
            for (var i = 0; i < team.length; i++) {
                if (team[i].main === 1) {
                    mainIndex = i;
                    break;
                }
            }
            if (mainIndex !== -1) return;
        }
        postAPICall('course/mainTeam', {userId: userId, token: token, course: course, 
            teacher: team[index].teacher, main: main}, (data) => {
            if (data && 'error' in data && data.error === 'none') {
                const nextTeam = team.map((m, i) => {
                    if (i === index) return {...m, main: main};
                    else return m;
                });
                setTeam(nextTeam);
            }
        });
    }

    const saveError = (data) => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const saveMember = (index) => {
        if (index < team.length) {
            const m = team[index];
            postAPICall('course/saveMember', {userId: userId, token: token, course: course, 
                teacher: m.teacher, role: m.role, percentage: m.percentage}, (data) => {
                if (!data || !('error' in data) || data.error !== 'none') saveError();
                else saveMember(index + 1);
            }, saveError);
        }
    }

    const deleteMember = async (index) => {
        const m = team[index];
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити цього члена команди?', dicLang)})) {
            postAPICall('course/deleteMember', {userId: userId, token: token, course: course, teacher: m.teacher}, 
                (data) => {
                    var copy = [...team];
                    copy.splice(index, 1);
                    setTeam(copy);
                }, saveError);
        }
    }

    const newMemberError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("за вказаною електронною адресю немає зареєстрованого користувача", dicLang));
    }

    const newMember = (email) => {
        const percentage = team.length === 0 ? 100 : 0; 
        postAPICall('course/newMember', {userId: userId, token: token, course: course, email: email,
            percentage: percentage},
            (data) => {
                if (data && 'id' in data && 'name' && data) {
                    setTeam([...team, {teacher: data.id, name: data.name, role: '', main: 0, percentage: percentage}]);
                } else newMemberError();
            }, newMemberError);
    }

    const save = () => {
        var total = 0;
        for (var i = 0; i < team.length; i++) {
            total += team[i].percentage;
        }
        if (total === 100) saveMember(0);
        else alert(translate("Помилка", dicLang) + ": " + translate("відсоток гонорару курсу в сумі має складати 100%", dicLang));
    }

    return <Accordion.Item eventKey="2">
        <Accordion.Header onClick={() => onToggle("2")}>
            <BsPeopleFill size="24" style={{margin: "1px 8px 0px 0px"}}/>
            <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Команда</Translate></span>
        </Accordion.Header>
        <Accordion.Body>
            {activeKey === "2" && <>
                {team.map((member, i) => {
                    return <TeamMember key={i} member={member} index={i} setMain={setMain} setPercentage={setPercentage}
                        setRole={setRole} deleteMember={deleteMember} dicLang={dicLang}
                        isDirector={isDirector} setChanged={setChanged}/>
                })}
                <div style={{textAlign: "center", marginTop: "16px", paddingBottom: "16px", borderBottom: "solid thin #cfe2ff"}}>
                    <Button size="sm" disabled={!changed} variant="outline-primary" onClick={() => { 
                        save(); setChanged(false); }}>
                        <Translate>Зберегти зміни</Translate></Button>
                </div>
                <Form.Label><Translate>Напишіть електронну адресу зареєстрованого користувача, щоб додати його до команди як викладача (або більше, якщо на курсі передбачена участь кількох викладачів).</Translate></Form.Label>
                <div style={{display: "flex", alignItems: "flex-start"}}>
                    <Form.Control disabled={!isDirector} value={email} type="text" style={{maxWidth: "320px", marginRight: "8px"}}
                        onChange={(e) => { setEmail(e.target.value); }}/>
                    <Button disabled={email.length===0 || !isDirector} onClick={() => { newMember(email); }}><Translate>Додати</Translate></Button>
                </div>
            </>}
        </Accordion.Body>
    </Accordion.Item>
}

export default CabEditCourseTeam